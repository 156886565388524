.sv-sharedlinks-list {
   padding-left: 0;
   list-style-type: none;
   display: block
}

.sv-no-touch .sv-sharedlinks-portlet .sv-sharedlink-item:hover a.sv-sharedlink-close.sv-sharedlink-remove-sharedlink {
   display: inherit;
}

.sv-no-touch .sv-sharedlinks-portlet .sv-sharedlink-item:hover a.sv-sharedlink-edit-sharedlink {
   display: inherit;
   margin-right: 15px;
   line-height: 1.7em;
   right: 5px
}

.sv-no-touch .sv-sharedlinks-portlet a.sv-sharedlink-close.sv-sharedlink-remove-sharedlink,
.sv-no-touch .sv-sharedlinks-portlet a.sv-sharedlink-close.sv-sharedlink-edit-sharedlink {
   display: none
}

.sv-touch .sv-sharedlinks-portlet a.sv-sharedlink-close.sv-sharedlink-remove-sharedlink,
.sv-touch .sv-sharedlinks-portlet a.sv-sharedlink-close.sv-sharedlink-edit-sharedlink {
   display: inherit;
   opacity: 1;
   padding: 2px
}

.sv-touch .sv-sharedlinks-portlet a.sv-sharedlink-close.sv-sharedlink-edit-sharedlink {
   right: 20px;
   top: 6px
}

.sv-sharedlink-remove-sharedlink,
.sv-sharedlink-edit-sharedlink {
   text-decoration: none;
}

.sv-no-touch .sv-sharedlinks-portlet .sv-sharedlink-item:hover {
   background-color:#F5F5F5;
   border-radius:4px;
   box-shadow:inset 0 1px 1px #999
}

.sv-sharedlinks-portlet li.sv-sharedlink-item {
   position:relative;
   cursor:pointer;
   display: block;
   padding:3px 5px;
   min-height: 18px
}

.sv-sharedlinks-portlet .sv-sharedlink-remove-sharedlink, .sv-sharedlinks-portlet .sv-sharedlink-edit-sharedlink {
   position:absolute;
   text-decoration:none;
   top:2px;
   right:2px
}

.sv-sharedlinks-portlet .input-append button i {
   vertical-align:middle
}

.sv-sharedlinks-error-message {
   color: #a94442;
   margin: .2em 0 .3em;
   font-size: 110%
}

.sv-sharedlinks-portlet .sv-button-container {
   margin-left: 8px
}

.sv-fn-change-btn.sv-change-btn.sv-add-link.btn {
   padding: 5px 6px 5px 8px
}

.bootstrap .sv-sharedlinks-form input[type="text"].sv-sharedlinks-has-error {
   border-color: #a94442;
   box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075)
}

.sv-sharedlinks-highlight-flash {
   animation: sv-sharedlinks-flash 2s
}

@keyframes sv-sharedlinks-flash {
  0% {
     background-color: #faebcc
  }
  50% {
     background-color: #fcf8e3
  }
}

.sv-sharedlink-tooltip .sv-sharedlink-tooltiptext {
   visibility: hidden;
   background-color: #555;
   color: #fff;
   text-align: center;
   border-radius: 6px;
   padding: 5px 5px;
   position: absolute;
   z-index: 1;
   bottom: 125%;
   left: 30%;
   margin-left: -60px;
   opacity: 0;
   transition: opacity 1s
}

.sv-sharedlink-tooltip .sv-sharedlink-tooltiptext::after {
   content: "";
   position: absolute;
   top: 100%;
   left: 30%;
   margin-left: -5px;
   border-width: 5px;
   border-style: solid;
   border-color: #555 transparent transparent transparent
}

.sv-no-touch .sv-sharedlink-tooltip:hover .sv-sharedlink-tooltiptext {
   visibility: visible;
   opacity: 1
}

.sv-sharedlink-item > a > .sv-sharedlinks-title {
   display: inline-block;
   max-width: 190px;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   vertical-align: middle
}

.sv-fn-sharedlink-item {
   display: inline-block;
   text-decoration: none;
   width: 100%
}

.sv-sharedlink-close {
   font-size: 20px;
   font-weight: bold;
   line-height: 20px;
   color: #000;
   text-shadow: 0 1px 0 #fff;
   opacity: .2
}

.sv-no-touch .sv-sharedlink-close:hover {
   color: #000000;
   text-decoration: none;
   cursor: pointer;
   opacity: 0.5;
   filter: alpha(opacity=50)
}

.sv-fn-sharedlink-item>i.halflings-icon.link {
   vertical-align: middle
}
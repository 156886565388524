/*.sv-groupsearch-popover.popover input.search-query {
   width: 85%;
}*/
.sv-groupsearch-popover.popover {
  width: 300px;
}

.sv-groupsearch-popover.popover input[data-fn-group-search] {
  width: 150px;
  height: 16px;
  margin-top: 2px;
}

.sv-groupsearch-popover.popover button.sv-fn-create-group {
  height: 26px;
  margin-top: 2px;
}

.sv-groupsearch-popover.popover form {
  margin: 0;
}

.sv-groupsearch-popover.popover .popover-content {
  font-size: 11px;
  padding: 5px 0px 5px 0px;
}

.sv-group-search-list li {
  padding: 3px 10px 3px 10px;
}

.sv-group-search-list .sv-group-img {
  float: left;
}

.sv-group-search-list .sv-group-info-container {
  overflow: hidden;
  vertical-align: top;
  padding-left: 3px;
}

.sv-group-search-list .sv-group-info-container h3 {
  margin: 0;
  display: block;
}

.sv-group-search-list li.active h3,
.sv-group-search-list li.active p {
  color: #ffffff;
}

.sv-group-search-list li.active,
.sv-group-search-list li:focus {
  text-decoration: none;
  background-color: #0081c2;
  background-image: linear-gradient(to bottom, #0088cc, #0077b3);
  background-repeat: repeat-x;
}

/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author sjostrand
 */
.sv-personalsettings-portlet .sv-personalsettings-nounderline {
   text-decoration:none
}

.sv-personalsettings-portlet .sv-personalsettings-content {
   padding:1em

}

.sv-personalsettings-portlet .sv-personalsettings-button {
   border:1px solid transparent;
   padding:2px
}

.sv-personalsettings-portlet .sv-personalsettings-button:hover {
   background-color:whiteSmoke;
   border:1px solid #eee
}

.sv-personalsettings-portlet .sv-personalsettings-button:active {
   border:1px solid grey
}

.sv-personalsettings-portlet .sv-buddy-icon {
   vertical-align:middle
}

.sv-personalsettings-portlet .sv-personalsettings-dropdown {
   font-size:14px
}

.sv-personalsettings-portlet .dropdown {
display:inline
}


.sv-userfields-portlet .sv-userfield-label {
  margin-right:5px
}

.sv-userfields-portlet .sv-userfield-custom-icon {
   vertical-align:top;
   width:16px;
   height:16px
}

.sv-userfields-portlet .sv-userfield-value {
  min-height:16px;
  margin-left:0
}

.sv-userfields-portlet .sv-userfield-value.sv-user-editable {
   position:relative
}

.sv-userfields-portlet .sv-userfield-value.sv-user-editable:hover {
   background-color:whitesmoke
}

.sv-userfields-portlet .sv-userfield-value.sv-user-editable:active {
   top:1px
}

.sv-userfields-portlet .sv-edit-userfields-btn {
   float:left;
   clear:both;
   margin-top:10px
}
/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author Karl Eklöf
 */

[data-user-identity]+.popover {
   width:300px
}

.popover h3 {
   display:block !important
}

.popover .popover-title small {
   color: #999
}

.webui-popover {
   max-width: 400px;
}
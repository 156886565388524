.sv-usergroupsall-portlet .sv-usergroup-link {
   text-decoration: none;
}

.sv-usergroupsall-portlet .sv-usergroup-badge::before {
   content: '(';
}

.sv-usergroupsall-portlet .sv-usergroup-badge::after {
   content: ')';
}

.sv-usergroupsall-portlet .sv-controls {
   width:100%;
   padding:5px 0px
}

.sv-usergroupsall-portlet .sv-controls .sv-list-style-switch {
   float:left
}

.sv-usergroupsall-portlet .sv-usergroup-name {
   display:inline
}

.sv-usergroupsall-portlet .sv-controls .sv-filter-label {
   margin:5px 1em 0 0
}

.sv-no-touch .sv-usergroupsall-portlet .sv-usergroup-list-item:hover {
   min-height:20px;
   background-color:#F5F5F5;
   border-radius:4px;
   box-shadow:inset 0 1px 1px #999
}

.sv-usergroupsall-portlet .sv-usergroup-list-item {
   position:relative;
   cursor:pointer
}

.sv-no-touch .sv-usergroupsall-portlet .sv-usergroup-list-item:hover .sv-usergroup-leave {
   display:inherit
}

.sv-no-touch .sv-usergroupsall-portlet .sv-usergroup-leave {
   display:none
}

.sv-usergroupsall-portlet .sv-usergroup-leave {
   position:absolute;
   top:5px;
   right:5px;
   text-decoration:none
}

.sv-usergroupsall-portlet .sv-list-2-columns {
   list-style:none;
   padding:0;
   margin:0
}

.sv-usergroupsall-portlet .sv-list-2-columns li {
   float:left;
   width:50%;
   padding:8px;
   text-align:left;
   word-wrap:break-word;
   box-sizing:border-box
}

.sv-usergroupsall-portlet .sv-list-2-columns .sv-usergroup-name {
   margin:0 0 4px 0
}

.sv-usergroupsall-portlet .sv-list-2-columns .sv-usergroup-img {
   border-style: none; /* No borders in IE */
   width:48px;
   margin:0
}

.sv-usergroupsall-portlet .sv-list-2-columns .sv-usergroup-info {
   overflow: hidden  /* information should not float down underneath buddy icon */
}

.sv-usergroupsall-portlet .sv-list-2-columns .sv-usergroup-info br:last-child {
   display:none
}

.sv-usergroupsall-portlet .sv-list-5-columns {
   list-style:none;
   padding:0;
   margin:0
}

.sv-usergroupsall-portlet .sv-list-5-columns li {
   float:left;
   width:20%;
   padding: 8px;
   text-align:center;
   word-wrap:break-word;
   box-sizing:border-box;
   height: auto !important;
}

.sv-usergroupsall-portlet .sv-list-5-columns .sv-usergroup-name {
   margin-bottom:0;
   height:2.5em;
   overflow:hidden;
   display:block
}

.sv-usergroupsall-portlet .sv-list-5-columns .sv-usergroup-img {
   border-style: none; /* No borders in IE */
   width:48px;
   height:48px
}

.sv-usergroupsall-portlet .sv-list-5-columns .sv-badge,
.sv-usergroupsall-portlet .sv-list-5-columns .sv-usergroup-info {
   display:none;
}

@media only screen and (max-width:768px) {
   .sv-usergroupsall-portlet .sv-list-5-columns li {
      width:25%
   }
}

@media only screen and (max-width:500px) {
   .sv-usergroupsall-portlet .sv-list-5-columns li {
      width:33%
   }
}

@media only screen and (max-width:500px) {
   .sv-usergroupsall-portlet .sv-list-2-columns li {
      float:none;
      width:100%
   }
}
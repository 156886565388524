 .sv-usercontactsall-portlet .sv-list-5-columns,
 .sv-usercontactsall-portlet .sv-list-2-columns {
    list-style:none;
    padding:0;
    margin:0
 }
 
 .sv-usercontactsall-portlet .sv-list-5-columns li {
    float:left;
    width:20%;
    text-align:center;
    height:auto !important
 }
 
 .sv-usercontactsall-portlet .sv-social-contact .sv-status-icon {
    vertical-align:middle
 }
 
 .sv-usercontactsall-portlet .sv-usercontactsall-status {
    color: #999;
    font-weight: normal
 }
 
 @media only screen and (max-width:768px) {
    .sv-usercontactsall-portlet .sv-list-5-columns li {
       width:25%
    }
 }
 
 @media only screen and (max-width:500px) {
    .sv-usercontactsall-portlet .sv-list-5-columns li {
       width:33%
    }
 }
 
 .sv-usercontactsall-portlet .sv-list-2-columns li {
    float:left;
    width:50%;
    overflow:hidden;
 }
 
 @media only screen and (max-width:500px) {
    .sv-usercontactsall-portlet .sv-list-2-columns li {
       float:none;
       width:100%
    }
 }
 
 .sv-usercontactsall-portlet li {
    box-sizing:border-box;
    padding:0;
    margin:0
 }
 
 .sv-usercontactsall-portlet .sv-list-5-columns .sv-contact-name {
    margin-bottom:0;
    height:2.5em;
    overflow:hidden;
    display:block
 }
 
 .sv-usercontactsall-portlet .sv-list-5-columns .sv-contact-img {
    width:48px;
    height:48px
 }
 
 /* User fields should not float down underneath buddy icon */
 .sv-usercontactsall-portlet .sv-list-2-columns .sv-contact-userfield {
    overflow:hidden
 }
 
 .sv-usercontactsall-portlet .sv-list-5-columns .sv-contact-userfield {
    display:none
 }
 
 .sv-usercontactsall-portlet .sv-list-2-columns .sv-contact-userfield br:last-child {
    display:none
 }
 
 
 /* list  2 - columns */
 .sv-usercontactsall-portlet .sv-list-2-columns li {
    text-align:left;
    padding:8px;
    word-wrap:break-word
 }
 
 .sv-usercontactsall-portlet .sv-list-2-columns .sv-contact-name {
    margin:0 0 4px 0;
    display:block
 }
 
 .sv-usercontactsall-portlet .sv-list-2-columns .sv-contact-img {
    float:left;
    width:48px;
    margin:0 1em 0 0
 }
 
 .sv-usercontactsall-portlet .sv-controls {
    width:100%;
    padding:5px 0
 }
 
 .sv-usercontactsall-portlet .sv-controls .sv-list-style-switch {
    float:left
 }
 
 .sv-no-touch .sv-usercontactsall-portlet .sv-social-contact:hover {
    min-height:20px;
    background-color:#F5F5F5;
    border-radius:4px;
    box-shadow:inset 0 1px 1px #999
 }
 
 .sv-usercontactsall-portlet .sv-social-contact {
    position:relative;
    cursor:pointer
 }
 
 .sv-no-touch .sv-usercontactsall-portlet .sv-social-contact:hover .sv-remove {
    display:inherit
 }
 
 .sv-no-touch .sv-usercontactsall-portlet .sv-remove {
    display:none
 }
 
 .sv-usercontactsall-portlet .sv-remove {
    position:absolute;
    top:5px;
    right:5px;
    text-decoration:none
 }
 
 .sv-usercontactsall-portlet .sv-filter-label {
    margin:5px 1em 0 0
 }
/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @see NotificationsAction
 *
 * @author micke
 */
.sv-notifications-portlet .sv-notification-new:before {
   content: "";
   display: block;
   float: left;
   width: 10px;
   height: 10px;
   margin-right: .5em;
   margin-top: 4px;
   background-color: #AAA;
   border-radius: 10px
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

.sv-notifications-portlet .sv-notification {
   vertical-align:top;
}

.sv-notifications-portlet {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #000;
}

.sv-notifications-portlet .popover * {
   font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.sv-notifications-portlet a[data-fn-drop-down] {
   position: relative;
}

.sv-notifications-portlet .sv-newnotification-badge {
   box-sizing: border-box !important;
   position: absolute !important;
   top: 1px !important;
   right: 100% !important;
   margin-right: -24px !important;
   min-width: 14px !important;
   height: 14px !important;
   line-height: 14px !important;
   padding: 0 3px !important;
   border-radius: 7px !important;
   background: #d9534f !important;
   color: #fff !important;
   font-size: 9px !important;
   font-family: Arial, sans-serif !important;
   font-weight: bold !important;
}

.sv-notifications-portlet .sv-notifications-notifier {
   vertical-align:top;
   font-weight:bold;
   color: #000;
}

.sv-notifications-portlet .sv-notifications-show-more {
   cursor:pointer;
   text-align:center
}

.sv-notification-list li {
   font-size: 11px;
   padding: 0 0 0 10px;
}

.sv-notifications-portlet .sv-notifications-show-more {
   font-size: 11px;
   padding: .25em 0;
}


.sv-notification-list a {
   text-decoration:none
}

.sv-notifications-portlet .sv-show-scroll {
   overflow:auto;
   position:relative
}

.sv-notification-list li .sv-buddy-icon {
   float:left;
   margin: 3px 0;
}

.sv-notifications-portlet .popover-content {
   font-size: 11px;
   padding: 5px 0px 5px 0px;
}

.sv-notifications-portlet .sv-notification-text {
   vertical-align:top;
}

.sv-notifications-portlet .sv-notification-text a {
   display: block;
   padding: 3px 10px 3px 0;
   min-height: 31px;
   color: #777;
}

.sv-notification-list li:hover div.sv-notification-text span,
.sv-notification-list li:focus div.sv-notification-text span,
.sv-notification-list li:active div.sv-notification-text span {
   color: white !important
}

.sv-notification-list li div.sv-notification-text {
   overflow:hidden;
   padding-left:5px;
   white-space:normal
}

.sv-notifications-portlet .sv-notifications-show-more:active,
.sv-notifications-portlet .sv-notifications-show-more:focus,
.sv-notifications-portlet .sv-notifications-show-more:hover,
.sv-notification-list li:active,
.sv-notification-list li:focus,
.sv-notification-list li:hover {
   text-decoration: none;
   color: white !important;
   background-color: #0081c2;
   background-image: linear-gradient(to bottom, #0088cc, #0077b3);
   background-repeat: repeat-x;
}

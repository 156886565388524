.sv-groupmember-list li.sv-groupadmin-member,
.sv-groupadmin-list li.sv-groupadmin-member {
   float:left;
   border:none;
   padding-right:3px;
   padding-bottom:3px;
   margin:0;
   height:32px;
   width:32px
}

.sv-groupmember-list img.sv-groupadmin-list-icon,
.sv-groupadmin-list img.sv-groupadmin-list-icon {
   height:32px;
   width:32px
}

.sv-groupadmin-portlet .sv-groupmember-list a {
   display:block;
   position:relative
}

.sv-groupadmin-portlet .sv-usersearch-popover h3 {
   display: block !important;
}

.bootstrap .btn.sv-groupadmin-add-button {
  padding: 5px 8px;
  font-size: 11.9px;
  border-radius: 3px;
}
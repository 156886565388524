.sv-timelineimages-portlet .sv-timeline-image-wrapper {
   position: relative;
   float: left;
   margin: 1px;
}

.sv-timelineimages-portlet .sv-timeline-image-wrapper:before {
   content: "";
   display: block;
   padding-top: 100%;
}

.sv-timelineimages-portlet .sv-timeline-image {
   position: absolute;
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center center;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
}

.sv-timelineimages-portlet .sv-timeline-image-svg {
   position: absolute;
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center center;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
}


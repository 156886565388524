/**
 * Copyright (C) SiteVision AB 2002-2013, all rights reserved
 *
 * @see GroupTypeSelectorAction
 *
 * @author Karl Eklöf
 */
.sv-grouptypeselector-portlet .sv-grouptypeselector-info {
   margin-top: 5px;
   margin-bottom: 5px;
}

.sv-grouptypeselector-portlet .sv-grouptypeselector-button {
   margin-left: 5px;
}

.sv-groupsettings-form .sv-group-admins {
   width:280px
}

.sv-groupsettings-form .sv-groupadmin-item {
   float: left;
   border:none;
   padding-right:3px;
   padding-bottom:3px;
   margin:0;
   height:32px;
   width:32px
}

.sv-grouptypeselector-portlet .sv-grouptypeselector-info,
.sv-grouptypeselector-portlet .sv-grouptypeselector-button {
   float:left
}


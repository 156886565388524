.sv-createdocument-portlet ol {
   list-style: none;
   padding: 0;
   margin: 0
}

.sv-createdocument-portlet .sv-template-img {
   float:left;
   width:16px;
   margin:0 .5em 0 0
}

.sv-createdocument-portlet .sv-template-listitem {
   position:relative;
   cursor:pointer;
   box-sizing:border-box;
   padding:0;
   margin:0;
   text-align: left;
   padding: 8px;
   word-wrap: break-word;
}

.sv-createdocument-portlet .sv-template-info {
   overflow: hidden;
}

.sv-createdocument-portlet a.sv-template-label {
   text-decoration: none;
}

.sv-createdocument-portlet .sv-template-listitem:hover {
   min-height:20px;
   background-color:#F5F5F5;
   border-radius:4px;
}
ul.triggeredInput-data-list {
   z-index: 1050;
   margin: 0;
   cursor: pointer;
   background-color: #FFF;
   border: 1px solid #CCC;
   list-style: none;

   font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
   font-size: 14px;
   line-height: 20px;
   color: #333;
   padding: 5px 0;
   box-sizing: border-box;
   border-radius: 6px;
   box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
   background-clip: padding-box;
}

ul.triggeredInput-data-list li {
   padding: 6px;
}

ul.triggeredInput-data-list li img {
   float: left;
   width: 20px;
   height: 20px;
   margin-right: 6px;
   border-radius: 3px;
}

ul.triggeredInput-data-list li span {
   font-weight: bold;
}

ul.triggeredInput-data-list li.selected {
   color: #FFF !important;
   background-color: #0081C2;
   background-image: linear-gradient(to bottom, #0088CC, #0077B3);
   background-repeat: repeat-x;
}

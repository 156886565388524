.sv-usergroups-portlet .sv-usergroups-list {
   list-style: none;
   padding: 0;
   margin:0 0 5px
}


.sv-usergroups-portlet .ui-body-c {
   border: none !important
}

.sv-usergroups-portlet .sv-usergroups-list li {
   text-align: left;
}

.sv-usergroups-portlet .sv-usergroup-img {
   border-style: none; /* No borders in IE */
   margin:0
}

.sv-usergroups-portlet .sv-usergroup-name {
   display:inline
}

.sv-usergroups-portlet .sv-usergroup-link {
   text-decoration: none;
}

.sv-usergroups-portlet .sv-message-container {
   overflow:hidden;
}

.sv-usergroups-portlet .sv-usergroup-info {
   margin-top: 0;
   display: block;
}

/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @see ProfileImageAction
 *
 * @author micke
 */
.sv-profileimage-portlet .sv-polaroid {
   width:100%;
   height:auto;
}

.sv-profileimage-portlet input {
   position: absolute;
   top: 0;
   left: 0;
   margin: 0;
   opacity: 0;
   filter: alpha(opacity=0);
   /*transform: translate(-300px, 0) scale(4);*/
   font-size: 26px !important;
   direction: ltr;
   cursor: pointer;
   height: 30px;
   line-height: 30px;
   width:100% !important
}

.sv-profileimage-portlet .sv-fileinput-field {
   overflow:hidden;
   position:relative
}

.sv-profileimage-portlet .dropdown {
   z-index:1000
}

.sv-profileimage-portlet .sv-change-btn {
   position:relative
}

.sv-usersearch-popover.popover input.search-query {
   width: 85%
}

.sv-usersearch-popover.popover .popover-content {
   font-size: 11px;
   padding: 5px 0px 5px 0px
}

.sv-user-search-list li {
   padding: 3px 10px 3px 10px
}

.sv-user-search-list .sv-contact-img  {
   float:left
}

.sv-user-search-list {
   overflow: auto
}

.sv-user-search-list .sv-user-info-container {
   overflow:hidden;
   vertical-align:top;
   padding-left:3px
}

.sv-user-search-list .sv-user-info-container h3 {
   margin:0
}

.sv-user-search-list .sv-showmore {
   text-align:right
}

.sv-user-search-list .sv-showmore a {
   text-decoration:none
}

.sv-user-search-list li {
   cursor: pointer
}

.sv-user-search-list li.active li,
.sv-user-search-list li.active h3,
.sv-user-search-list li.active p,
.sv-user-search-list li.active a {
   color: #ffffff;
}

.sv-user-search-list li.active,
.sv-user-search-list li:focus {
   text-decoration: none;
   background-color: #0081c2;
   background-image: linear-gradient(to bottom, #0088cc, #0077b3);
   background-repeat: repeat-x;
}


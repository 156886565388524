.sv-calendar-portlet .close,
.sv-calendar-portlet .bootstrap form {
   margin:0
}

form.sv-fn-event-edit.form-horizontal .control-group {
   margin-bottom: 10px;
}

.sv-calendar-portlet .popover-title {
   line-height:25px
}

.sv-calendar-portlet .sv-fn-create-event-form input[type=text] {
   width:100% !important;
   height:auto !important
}

.sv-calendar-portlet .sv-calendar-functions {
   padding-top:1em;
   margin-top:1em;
   border-top:1px solid #eee
}

.sv-calendar-portlet .sv-calendar-functions a,
.sv-calendar-portlet .fc-event-title {
   text-decoration:none
}

.sv-calendar-portlet .sv-calendar-functions a:hover,
.sv-calendar-portlet .sv-event-title-owner:hover {
   text-decoration:underline
}

.sv-calendar-portlet .sv-calendar-create-container,
.sv-calendar-portlet .sv-calendar-details-container {
   margin-top:.5em
}

.select2-drop ul.select2-results {
   z-index:1050;
   margin:0;
   cursor:pointer;
   /*background-color:#FFF;*/
   /*border: 1px solid#CCC;*/
   list-style:none;

   font-family:"Helvetica Neue", Helvetica, Arial, sans-serif;
   font-size:0.875rem;
   line-height:20px;
   color:#333;

   padding:5px 0;
   box-sizing:border-box;
   border-radius:6px;
   box-shadow:0 5px 10px rgba(0, 0, 0, 0.2);
   background-clip:padding-box
}

.select2-container ul.select2-choices li img,
.select2-drop ul.select2-results li img {
   float:left;
   width:20px;
   height:20px;
   margin-right:6px;
   border-radius:3px
}

.select2-container ul.select2-choices li span,
.select2-drop ul.select2-results li span {
   font-weight:bold
}

.bootstrap .select2-container.input-xlarge {
   width: 284px;
}

.select2-container .select2-choices {
   border-radius: 4px
}

.select2-container.select2-dropdown-open .select2-choices {
   border-bottom-right-radius:0;
   border-bottom-left-radius:0;
}

.select2-drop ul.select2-results li.select2-highlighted {
   color:#FFF !important;
   background-color:#0081C2;
   background-image: linear-gradient(to bottom, #0088CC, #0077B3);
   background-repeat:repeat-x;
}

.sv-calendar-portlet .dropdown-toggle {
   margin-top:0
}

.sv-calendar-portlet .sv-typeselector-dropdownmenu li a {
   text-align:left
}

.sv-calendar-portlet .fc-event-time {
   font:bold 10px Arial, sans-serif
}

.sv-calendar-portlet div.fc-header-title {
   margin-bottom:1em
}

.sv-calendar-portlet .fc-agendalist .sv-event,
.sv-calendar-portlet .fc-agendalist .sv-event-task {
   background-color:inherit !important
}

.sv-calendar-portlet .sv-event .fc-agendalist-time,
.sv-calendar-portlet .sv-event-task .fc-agendalist-time {
   padding:5px;
   vertical-align: top;
}

.sv-calendar-portlet .fc-agendalist-day-item {
   cursor:pointer
}

.sv-calendar-portlet .fc-event-inner {
   cursor:pointer;
}

.sv-calendar-portlet .fc-button {
   font-size:11px;
   padding:1px 6px
}

.sv-calendar-portlet .fc-agenda-list,
.sv-calendar-portlet .sv-calendar-element-icon {
   margin-left:0;
   margin-right:.5em
}

.sv-calendar-portlet .sv-readmore,
.sv-calendar-portlet .sv-more {
   cursor:pointer;
   font-size:smaller;
   font-weight:bold
}

.sv-calendar-portlet .sv-show-scroll {
   overflow:auto;
   position:relative;
   margin-right:-14px
}

.sv-calendar-portlet .sv-calendar-user-icon {
   vertical-align:middle !important
}

.sv-calendar-portlet .sv-typeselector-button {
   display: inline
}

.sv-calendar-portlet .fc-agendalist-title {
   width: 80%;
   padding: 5px;
}

.sv-taglist-portlet .sv-taglist {
   list-style:none;
   padding:0;
   margin:0 0 5px 0
}

/* Hovering */
.sv-no-touch .sv-taglist-portlet .sv-taglist-item:hover {
   /*min-height:20px;*/
   background-color:#F5F5F5;
   border-radius:4px;
   box-shadow:inset 0 1px 1px #999
}

.sv-taglist-portlet li.sv-taglist-item {
   position:relative;
   cursor:pointer;
   display: block;
   padding:3px 5px
}

.sv-no-touch .sv-taglist-portlet .sv-taglist-item:hover .sv-taglist-remove-portlet {
   display:inherit
}

.sv-no-touch .sv-taglist-portlet .sv-taglist-remove-portlet {
   display:none
}

.sv-taglist-portlet .input-append input.sv-taglist-input {
   height:14px;
   margin-top:2px
}

.sv-taglist-portlet .sv-fn-tag-link {
   text-decoration:none;
}

.sv-taglist-portlet .input-prepend .add-on {
   height: 14px !important;
   margin-top: 2px
}

.sv-taglist-portlet .input-append button[data-add-tag-button] {
   height: 24px;
   margin-top: 2px
}

.sv-taglist-portlet .input-append button i {
   vertical-align:middle
}

.sv-taglist-portlet .sv-taglist-remove-portlet {
   position:absolute;
   text-decoration:none;
   top:2px;
   right:2px
}


/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author Karl Eklöf
 */
.sv-timeline-portlet .sv-timeline,
.sv-timelinesearch-portlet .sv-timeline,
.sv-tagtimeline-portlet .sv-timeline {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sv-timeline-portlet .sv-timeline-input,
.sv-timelinesearch-portlet .sv-timeline-input,
.sv-tagtimeline-portlet .sv-timeline-input {
  width: 100%;
}

.sv-timeline-portlet .sv-character-limit-exceeded,
.sv-timelinesearch-portlet .sv-character-limit-exceeded,
.sv-tagtimeline-portlet .sv-character-limit-exceeded {
  color: red;
}

.sv-timeline-portlet .sv-timeline-entry,
.sv-timelinesearch-portlet .sv-timeline-entry,
.sv-tagtimeline-portlet .sv-timeline-entry {
  padding: 1em;
  border-top: 1px solid #eee;
}

.sv-no-touch .sv-timeline-portlet .sv-timeline-entry:hover,
.sv-no-touch .sv-timelinesearch-portlet .sv-timeline-entry:hover,
.sv-no-touch .sv-tagtimeline-portlet .sv-timeline-entry:hover {
  background-color: whiteSmoke;
}

.sv-timeline-portlet .sv-timeline-entry h3,
.sv-timelinesearch-portlet .sv-timeline-entry h3,
.sv-tagtimeline-portlet .sv-timeline-entry h3 {
  margin-top: 0;
}

.sv-timeline-portlet .sv-load-more,
.sv-timelinesearch-portlet .sv-load-more,
.sv-tagtimeline-portlet .sv-load-more {
  width: 100%;
}

.sv-timeline-portlet .sv-entry-menu,
.sv-timelinesearch-portlet .sv-entry-menu,
.sv-timelineentry-portlet .sv-entry-menu,
.sv-tagtimeline-portlet .sv-entry-menu {
  position: absolute;
  top: 0;
  right: 5px;
  text-decoration: none;
  display: none;
}

.sv-timelineentry-portlet .sv-entry-menu {
  display: inherit;
}

.sv-timeline-portlet .sv-entry-menu .chevron-down,
.sv-timelinesearch-portlet .sv-entry-menu .chevron-down,
.sv-timelineentry-portlet .sv-entry-menu .chevron-down,
.sv-tagtimeline-portlet .sv-entry-menu .chevron-down {
  opacity: 0.6;
}

.sv-no-touch .sv-timeline-portlet .sv-timeline-entry:hover .sv-entry-menu,
.sv-no-touch .sv-timelineentry-portlet .sv-timeline-entry:hover .sv-entry-menu,
.sv-no-touch .sv-timelinesearch-portlet .sv-timeline-entry:hover .sv-entry-menu,
.sv-no-touch .sv-tagtimeline-portlet .sv-timeline-entry:hover .sv-entry-menu {
  display: inherit;
}

.sv-no-touch .sv-timeline-portlet .sv-timeline-entry:hover .sv-remove-entry,
.sv-no-touch
  .sv-timelinesearch-portlet
  .sv-timeline-entry:hover
  .sv-remove-entry,
.sv-no-touch .sv-tagtimeline-portlet .sv-timeline-entry:hover .sv-remove-entry {
  display: inherit;
}

.sv-touch .sv-timeline-portlet .sv-timeline-entry .sv-entry-menu,
.sv-touch .sv-timelinesearch-portlet .sv-timeline-entry .sv-entry-menu,
.sv-touch .sv-timelineentry-portlet .sv-timeline-entry .sv-entry-menu,
.sv-touch .sv-tagtimeline-portlet .sv-timeline-entry .sv-entry-menu {
  display: inherit;
}

.sv-touch
  .sv-timeline-portlet
  .sv-timeline-entry
  .sv-entry-menu
  .dropdown-toggle,
.sv-touch
  .sv-timelineentry-portlet
  .sv-timeline-entry
  .sv-entry-menu
  .dropdown-toggle,
.sv-touch
  .sv-timelinesearch-portlet
  .sv-timeline-entry
  .sv-entry-menu
  .dropdown-toggle,
.sv-touch
  .sv-tagtimeline-portlet
  .sv-timeline-entry
  .sv-entry-menu
  .dropdown-toggle {
  padding: 3px;
}

.sv-timeline-portlet .sv-comment-menu,
.sv-timelinesearch-portlet .sv-comment-menu,
.sv-timelineentry-portlet .sv-comment-menu,
.sv-tagtimeline-portlet .sv-comment-menu {
  position: absolute;
  top: 0;
  right: 5px;
  text-decoration: none;
  display: none;
}

.sv-timeline-portlet .sv-comment-menu .chevron-down,
.sv-timelinesearch-portlet .sv-comment-menu .chevron-down,
.sv-timelineentry-portlet .sv-comment-menu .chevron-down,
.sv-tagtimeline-portlet .sv-comment-menu .chevron-down {
  opacity: 0.6;
}

.sv-no-touch
  .sv-timeline-portlet
  .sv-timeline-entry
  .sv-timeline-comment:hover
  .sv-comment-menu,
.sv-no-touch
  .sv-timelineentry-portlet
  .sv-timeline-entry
  .sv-timeline-comment:hover
  .sv-comment-menu,
.sv-no-touch
  .sv-timelinesearch-portlet
  .sv-timeline-entry
  .sv-timeline-comment:hover
  .sv-comment-menu,
.sv-no-touch
  .sv-tagtimeline-portlet
  .sv-timeline-entry
  .sv-timeline-comment:hover
  .sv-comment-menu {
  display: inherit;
}

.sv-touch
  .sv-timeline-portlet
  .sv-timeline-entry
  .sv-timeline-comment
  .sv-comment-menu,
.sv-touch
  .sv-timelinesearch-portlet
  .sv-timeline-entry
  .sv-timeline-comment
  .sv-comment-menu,
.sv-touch
  .sv-timelineentry-portlet
  .sv-timeline-entry
  .sv-timeline-comment
  .sv-comment-menu,
.sv-touch
  .sv-tagtimeline-portlet
  .sv-timeline-entry
  .sv-timeline-comment
  .sv-comment-menu {
  display: inherit;
}

.sv-touch
  .sv-timeline-portlet
  .sv-timeline-entry
  .sv-timeline-comment
  .sv-comment-menu
  .dropdown-toggle,
.sv-touch
  .sv-timelinesearch-portlet
  .sv-timeline-entry
  .sv-timeline-comment
  .sv-comment-menu
  .dropdown-toggle,
.sv-touch
  .sv-timelineentry-portlet
  .sv-timeline-entry
  .sv-timeline-comment
  .sv-comment-menu
  .dropdown-toggle,
.sv-touch
  .sv-tagtimeline-portlet
  .sv-timeline-entry
  .sv-timeline-comment
  .sv-comment-menu
  .dropdown-toggle {
  padding: 3px;
}

.sv-timeline-portlet .sv-add-file,
.sv-tagtimeline-portlet .sv-add-file,
.sv-timelinesearch-portlet .sv-add-file,
.sv-timelineentry-portlet .sv-add-file {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: 24px;
  width: 100%;
}

.sv-timeline-portlet .sv-file-input,
.sv-tagtimeline-portlet .sv-file-input,
.sv-timelinesearch-portlet .sv-file-input,
.sv-timelineentry-portlet .sv-file-input {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  width: 100% !important;
  height: 100%;
  opacity: 0;
  filter: alpha(opacity=0);
  /*transform:translate(-300px, 0) scale(4);*/
  font-size: 26px !important;
  direction: ltr;
}

.sv-timeline-portlet .sv-comments-loading,
.sv-timelinesearch-portlet .sv-comments-loading,
.sv-tagtimeline-portlet .sv-comments-loading,
.sv-timelineentry-portlet .sv-comments-loading {
  margin-top: 1em;
  display: none;
}

.sv-files-added,
.sv-comment-files-added,
.sv-subcomment-files-added,
.sv-timeline-portlet .sv-file-loading,
.sv-timeline-portlet .sv-comment-file-loading,
.sv-timeline-portlet .sv-subcomment-file-loading,
.sv-timelinesearch-portlet .sv-file-loading,
.sv-timelinesearch-portlet .sv-comment-file-loading,
.sv-timelinesearch-portlet .sv-subcomment-file-loading,
.sv-tagtimeline-portlet .sv-file-loading,
.sv-tagtimeline-portlet .sv-comment-file-loading,
.sv-tagtimeline-portlet .sv-subcomment-file-loading,
.sv-timelineentry-portlet .sv-file-loading,
.sv-timelineentry-portlet .sv-comment-file-loading,
.sv-timelineentry-portlet .sv-subcomment-file-loading {
  display: none;
}

.sv-timeline-portlet .sv-comments-loading img,
.sv-timelinesearch-portlet .sv-comments-loading img,
.sv-tagtimeline-portlet .sv-comments-loading img,
.sv-timelineentry-portlet .sv-comment-file-loading img {
  vertical-align: text-bottom;
}

.sv-timeline-portlet .sv-remove-attachment,
.sv-timelinesearch-portlet .sv-remove-attachment,
.sv-tagtimeline-portlet .sv-remove-attachment,
.sv-timelineentry-portlet .sv-remove-attachment {
  float: none !important;
  vertical-align: middle;
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  .sv-timeline-portlet .sv-timeline-entry,
  .sv-timelinesearch-portlet .sv-timeline-entry,
  .sv-tagtimeline-portlet .sv-timeline-entry {
    padding: 1em 0;
  }
}

.sv-timelinesearch-portlet .sv-ugc-search-form {
  margin-bottom: 0;
}

.sv-timelinesearch-portlet .sv-ugc-search-form .sv-ugc-search-input {
  height: 30px;
  box-sizing: border-box;
  width: 100%;
}

.emoji-picker {
  z-index: 10000;
}

.sv-emoji-button {
  cursor: pointer;
  display: inline-block;
  margin-top: -2px;
}

.sv-comments-emoji-button,
.sv-entries-emoji-button {
  margin-left: 5px;
}

.sv-likepage-portlet form.sv-likepage-form {
   overflow:hidden
}

.sv-likepage-portlet .sv-likepage-content {
   margin: 3px 0 5px 0
}

.sv-likepage-portlet .sv-meta-info {
   list-style:none;
   padding:0;
   box-sizing:border-box
}

.sv-likepage-portlet a {
   text-decoration:none;
   cursor:pointer
}

.sv-likepage-portlet li {
   float:left;
   margin-left:2.5em
}

.sv-likepage-portlet .halflings-icon {
   vertical-align: middle
}
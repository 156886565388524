
.sv-messages-portlet {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  color: #000;
}

.sv-messages-portlet .show-message-btn {
  position: relative;
}

.sv-messages-portlet .popover * {
   font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.sv-messages-portlet * {
    box-sizing: border-box;
}

.sv-messages-portlet ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.sv-messages-portlet .sv-chat-container {
   display: none;
   position: fixed;
   z-index: 5001;
   top: auto !important;
   right: 100px;
   bottom: 0px;
   left: auto;
   width: 400px;
   height: 450px;
   background-color: white;
   box-shadow: 0 5px 10px rgba(0,0,0,0.2);
}

@media (max-width: 768px) {
   .sv-messages-portlet .sv-chat-container {
      /* Must be important because of UI-draggable inline CSS */
      top: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      left: 0 !important;
      width: 100% !important;
      height: auto;
   }
}

@media (min-width: 769px) {
   .sv-messages-portlet .sv-chat-container {
      top: auto;
      right: 100px;
      bottom: 0px;
      left: auto;
      width: 400px;
      height: 450px;
   }
}

.sv-messages-portlet .sv-chat-container > .sv-chat-titlebar {
   position: fixed;
   z-index: 4;
   top: 0;
   left: 0;
   right: 0;
}

@media (min-width: 769px) {
   .sv-messages-portlet .sv-chat-container > .sv-chat-titlebar {
      position: relative;
   }
}

.sv-messages-portlet .sv-panel-body {
    position: absolute;
    top: 34px;
    bottom: 37px;
    left: 0;
    right: 0;
    overflow-y: scroll;
}

@media (min-width: 769px) {
   .sv-messages-portlet .sv-panel-body {

   }
}

.sv-messages-portlet .sv-panel-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    position: absolute;
    left: 25px;
    right: 25px;
    top: 7px;
    font-size: 14px;
}

.sv-messages-portlet .sv-panel-footer {
   position: absolute;
   padding: 3px;
   background-color: #f5f5f5;
   border-top: 1px solid #eee;
   right: 0;
   bottom: 0;
   left: 0;
}

.sv-messages-portlet .sv-participants-panel {
   position: absolute;
   z-index: 3;
   top: 35px;
   right: 0;
   left: 0;
   padding: 4px;
}

   .sv-messages-portlet .sv-participants-panel > a {
      width: 5%;
      padding-left: 3px;
   }

.sv-messages-portlet .popover-title a,
.sv-messages-portlet .popover-title a:hover {
   font-size: 12px;
   font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
   font-weight: normal;
   color: #777;
   margin-top: 3px;
}

   .sv-messages-portlet .popover-title a:hover {
      text-decoration: underline;
   }

.sv-messages-portlet .sv-conversation-list .sv-conversation {
   cursor: pointer;
}

   .sv-messages-portlet .sv-conversation-list .sv-conversation a {
      color: #777;
   }


   .sv-messages-portlet .sv-conversation-list .sv-conversation .sv-conversation-name {
      color: #000;
   }


.sv-messages-portlet .sv-chat-titlebar {
   cursor: default;
   background-color: #0081c2;
   background-image: linear-gradient(to bottom,#08c,#0077b3);
   padding: 5px;
   text-decoration: none;
}

   .sv-messages-portlet .sv-chat-titlebar .sv-panel-title,
   .sv-messages-portlet .sv-chat-titlebar .sv-panel-title > a {
      text-decoration: none;
      color: #fff;
   }

   .sv-messages-portlet .sv-chat-titlebar .icon-white {
      opacity: 1;
      margin: 3px;
   }

   .sv-messages-portlet .sv-chat-titlebar .sv-close {
      opacity: initial;
   }

.sv-messages-portlet input.select2-input[type=text],
.sv-messages-portlet input.sv-chat-input[type=text] {
   width: 100%;
   height: 30px;
   margin-bottom: 0;
}

@media (max-width: 768px) {
    .sv-messages-portlet input.sv-chat-input[type=text] {
        font-size: 16px;
    }
}

.sv-messages-portlet input.select2-input[type=text] {
   height: 26px;
}

.sv-messages-portlet .sv-chat .sv-message {
    position: relative;
    margin: 10px;
    padding-bottom: 5px;
    border-bottom: 1px dotted #B3A9A9;
}

   .sv-messages-portlet .sv-chat .sv-message .sv-chat-img {
      position: absolute;
      top: 2px;
      left: 0;
   }

      .sv-messages-portlet .sv-chat .sv-message.sv-right .sv-chat-img {
         left: auto;
         right: 0;
      }

   .sv-messages-portlet .sv-chat .sv-message .sv-chat-body {
       min-height: 40px;
   }

      .sv-messages-portlet .sv-chat .sv-message.sv-left .sv-chat-body {
          padding-left: 40px;
      }

      .sv-messages-portlet .sv-chat .sv-message.sv-right .sv-chat-body {
          padding-right: 40px;
      }

      .sv-messages-portlet .sv-chat .sv-message .sv-chat-body .sv-header .sv-timestamp {
         position: absolute;
         right: 0;
      }

      .sv-messages-portlet .sv-chat .sv-message.sv-right .sv-chat-body .sv-header .sv-timestamp {
         position: static;
      }

      .sv-messages-portlet .sv-chat .sv-message.sv-right .sv-chat-body .sv-header .sv-username {
         position: absolute;
         right: 40px;
      }

   .sv-messages-portlet .sv-chat .sv-message .sv-chat-body p {
       margin: 0;
       color: #777777;
   }

   .sv-messages-portlet .sv-chat .sv-message.sv-right .sv-chat-body p {
       float: right;
   }

.sv-messages-portlet .sv-chat .sv-typing {
   border-bottom: none;
   padding: 5px 10px 10px;
}

   .sv-messages-portlet .sv-chat .sv-typing .icon-comment {
      margin-top: 2px;
   }

.sv-messages-portlet .sv-show-more-container {
   width: 100%;
   padding: 3px 20px;
}


.sv-messages-portlet .sv-message-badge {
   position: absolute !important;
   top: 1px !important;
   right: 100% !important;
   box-sizing: border-box !important;
   margin-right: -24px !important;
   min-width: 14px !important;
   height: 14px !important;
   line-height: 14px !important;
   padding: 0 3px !important;
   border-radius: 7px !important;
   background: #d9534f !important;
   color: #fff !important;
   font-size: 9px !important;
   font-family: Arial,sans-serif !important;
   font-weight: bold !important;
}

   .sv-messages-portlet .sv-message-badge img {
      position: absolute;
      top: 1px;
      left: 2px;
      display: block;
      width: 12px;
      border: 0;
   }



.sv-conversation-icon {
   position: relative;
   float: left;
   overflow: hidden;
   border-radius: 3px;
   height: 48px;
   width: 48px;
}

   .sv-conversation-icon div {
      position: absolute;
      overflow: hidden;
      width: 48px;
      height: 48px;
   }

   .sv-conversation-icon img {
      position: absolute;
   }


/* Conversation icon 2 members */
.sv-conversation-icon.sv-conversation-count-2 div {
   width: 24px;
}

   .sv-conversation-icon.sv-conversation-count-2 div:nth-child(2) {
      left: 24px;
   }

   .sv-conversation-icon.sv-conversation-count-2 div img {
      left: -12px;
   }

/* Conversation icon 3 members */
   .sv-conversation-icon.sv-conversation-count-3 div {
      width: 24px;
   }

      .sv-conversation-icon.sv-conversation-count-3 div:nth-child(1) img {
         left: -12px;
      }

      .sv-conversation-icon.sv-conversation-count-3 div:nth-child(2) {
         left: 24px;
         height: 24px;
      }


      .sv-conversation-icon.sv-conversation-count-3 div:nth-child(3) {
         left: 24px;
         height: 24px;
         top: 24px;
      }

      .sv-conversation-icon.sv-conversation-count-3 div:nth-child(2) img,
      .sv-conversation-icon.sv-conversation-count-3 div:nth-child(3) img {
         width: 24px;
         height: 24px;
      }

   /* Conversation icon 4 members */
   .sv-conversation-icon.sv-conversation-count-4 div,
   .sv-conversation-icon.sv-conversation-count-4 img {
      width: 24px;
      height: 24px;
   }

      .sv-conversation-icon.sv-conversation-count-4 div:nth-child(2),
      .sv-conversation-icon.sv-conversation-count-4 div:nth-child(4) {
         left: 24px;
      }

      .sv-conversation-icon.sv-conversation-count-4 div:nth-child(3),
      .sv-conversation-icon.sv-conversation-count-4 div:nth-child(4) {
         top: 24px;
      }

.sv-chat-container .sv-close {
   font-size: 20px;
   font-weight: bold;
   line-height: 20px;
   color: #000;
   text-shadow: 0 1px 0 #fff;
}

.sv-instant-message-width {
   max-width:325px;
}


.sv-fileshareall-portlet .tabbable .nav {
   padding-left:0;
   margin-bottom:3px;
   margin-top:3px;
}

.sv-svg-fileview-size {
   width: 80%;
   max-height: 80%;
}

.sv-fileshareall-portlet #appletHolder {
   height:1px;
}

.sv-fileshareall-portlet ul.dropdown-menu i.halflings-icon {
   vertical-align: text-bottom;
}

.sv-fileshareall-portlet article {
   margin: 1em 1em 0 1em
}

.sv-fileshareall-portlet .sv-download-wrapper {
   margin-top: .5em;
}


.sv-fileshareall-portlet table td.sv-file-list-icon {
   vertical-align:middle
}

.sv-fileshareall-portlet .sv-file-comments .sv-message-content {
   margin: 3px 0 5px 0
}

.sv-fileshareall-portlet .sv-file-comment {
   position: relative
}

.sv-fileshareall-portlet .sv-lockinfo-container {
   position:absolute;
   top:0;
   right:0;
   background-color: #FFF;
   padding:2px 2px 2px 5px;
}

.sv-fileshareall-portlet .tabbable .tab-content {
   overflow: inherit
}

.sv-fileshareall-portlet .table .sv-file-version-actions {
   text-align:right
}

.sv-fileshareall-portlet ul.dropdown-menu  {
   text-align:left
}


.sv-fileshareall-portlet .sv-file-actions a.btn {
   /*margin-right: 20px*/
}

.sv-fileshareall-portlet .sv-buddy-icon {
   margin-right:5px
}

.sv-fileshareall-portlet .sv-file-actions {
   /*margin: 20px 0 20px 26px*/
   position:absolute;
   top:30px;
   right:0;
}

.sv-fileshareall-portlet .breadcrumb.sv-breadcrumb {
   margin:0
}

.sv-fileshareall-portlet .sv-controls {
   margin-top: 1em
}

.sv-fileshareall-portlet .nav-tabs a {
   color:#0088cc;
   font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
   font-size: 13px;
   text-decoration: none
}

.sv-fileshareall-portlet .nav-tabs li.active a {
   color: #555555
}

form.sv-file-actions-form {
   margin: 0 !important
}

.sv-file-pdf-preview {
   position: relative;
   padding-bottom: 75%;
   height: 0;
   overflow: hidden
}

.sv-fileshareall-portlet div.sv-file-container {
   position:relative;
   width:100%

}

.sv-fileshareall-portlet .table-wrapper {
   display:table;
   width:100%
}

.sv-fileshareall-portlet .sv-file-metadata-container {
   display:table-cell;
}

.sv-fileshareall-portlet .preview {
   display:table-cell;
   vertical-align: top;
   width:100px;
}

.sv-fileshareall-portlet dl.dl-horizontal dt  {
   text-align: left;
   vertical-align: top;
   width:125px
}

.sv-fileshareall-portlet .sv-file-pdf-preview iframe {
   position: absolute;
   border-width: 0;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%
}

.sv-fileshareall-portlet .editable-inline form,
.sv-fileshareall-portlet .popover form {
   margin:0 !important;
}

.sv-fileshareall-portlet .sv-file-name {
   margin:0 0 10px 0;
   display:block;
   width:100%;
   border-bottom: 1px solid #e5e5e5;
   padding: 0 0 4px 0;
}

.sv-fileshareall-portlet .sv-file-icon {
   margin: -6px 0 0 5px;
   vertical-align: middle
}

.sv-fileshareall-portlet .sv-file-img {
   margin:0 10px 0 0;
   width: 16px;

}

.sv-fileshareall-portlet .sv-meta-info {
   list-style:none;
   padding:0;
   box-sizing:border-box
}

.sv-fileshareall-portlet .sv-meta-info a {
   text-decoration:none;
   cursor:pointer
}

.sv-fileshareall-portlet .sv-meta-info li {
   float:left;
   margin-left:2.5em
}

.sv-fileshareall-portlet .sv-meta-info li:first-child {
   margin-left:0
}

.sv-fileshareall-portlet .sv-file-entry img.sv-file-icon {
   max-width:48px;
   margin-right:1em
}

.sv-fileshareall-portlet .sv-file-description-container {
   position: relative;
}

.sv-fileshareall-portlet .sv-file-entry .sv-file-img img {
   max-width:100px;
   margin-right:1em
}

.sv-fileshareall-portlet .sv-input-section {
   position: relative
}

.sv-fileshareall-portlet .sv-input-section img.sv-input-buddy-icon {
   position: absolute;
   top:1em;
   left:1em
}

.sv-fileshareall-portlet .sv-button-container {
   margin-left: 5px;
}

.sv-fileshareall-portlet .sv-file-browse-area {
   display: flex;
   margin-top: 1em;
   white-space: nowrap;
}

.sv-fileshareall-portlet input.sv-search-filter-field.input-medium {
   margin: 0 .5em;
   min-width: 170px;
   width: 100%;
   font-size: 0.875rem;
}

.sv-fileshareall-portlet .sv-input-section form {
   margin: 0;
   padding-left:40px
}

.sv-fileshareall-portlet table tr[class] {
   display:table-row
}

.sv-fileshareall-portlet td {
   display:table-cell !important
}

.sv-fileshareall-portlet .sv-message-container {
   overflow:hidden;
   margin-left: 40px;
}

.sv-fileshareall-portlet .halflings-icon {
   vertical-align: middle
}

.sv-fileshareall-portlet .sv-message-header {
   margin:0
}

.sv-fileshareall-portlet .sv-shared-entry {
   border-left:2px solid #EEE;
   margin:1em 0;
   padding-left:1em
}


.sv-fileshareall-portlet .sv-fileinput-field.sv-change-btn {
   overflow:hidden
}

.sv-fileshareall-portlet .sv-shared-entry img {
   width:36px
}

.sv-fileshareall-portlet .sv-timeline-entry-comments {
   background-color:whiteSmoke
}

.sv-fileshareall-portlet .sv-comment-list {
   margin:0;
   padding:0;
   list-style:none
}

.sv-fileshareall-portlet .sv-comment-list .sv-file-comment {
   border-bottom:1px solid #EEE;
   padding:1em;
   margin:0
}

.sv-fileshareall-portlet .sv-input-section {
   padding:1em 1em 2em 1em
}

.sv-fileshareall-portlet .sv-cut .sv-file-checkbox,
.sv-fileshareall-portlet .sv-cut .sv-file-field,
.sv-fileshareall-portlet .sv-cut .sv-file-field * {
   font-style: italic;
   opacity:0.7;
   filter:alpha(opacity=70); /* For IE8 and earlier */
}

.sv-fileshareall-portlet .sv-comment-input {
   width:100%;
}

.sv-fileshareall-portlet .sv-filebrowse-nav {
   min-width: 200px;
   flex: 0.1;
}

.sv-fileshareall-portlet .sv-filebrowse-list {
   flex: 1;
   overflow-x: auto;
}

.sv-fileshareall-portlet .sv-version-name a {
   text-decoration:none
}

.sv-fileshareall-portlet .sv-version-name a.sv-file-download-link:hover {
   text-decoration:underline
}

.sv-fileshareall-portlet .sv-filebrowse-nav .nav-list {
   margin-top: .5em;
}

.sv-fileshareall-portlet .sv-filebrowse-nav .well {
   padding:8px 0;
   margin-right:10px;
   overflow:hidden
}

.sv-fileshareall-portlet .sv-filebrowse-nav a {
   text-decoration:none
}

.sv-fileshareall-portlet .sv-filebrowse-taglist .nav-header {
   font-size: 0.688rem;
}

.sv-fileshareall-portlet input[type="checkbox"].sv-file-checkbox {
   margin-left: 1em;
}

.sv-fileshareall-portlet .sv-sort-toggle {
   background-repeat: no-repeat;
   background-position: center right;
   cursor: pointer;
   user-select: none;
}

.sv-fileshareall-portlet .sv-sort-asc {
   background-image: url(../img/asc.gif);
}

.sv-fileshareall-portlet .sv-sort-desc {
   background-image: url(../img/desc.gif);
}

.sv-fileshareall-portlet label.sv-search-filter-label {
   padding-left: 6px;
}

.sv-fileshareall-portlet .sv-breadcrumb > li > a span {
   font-size: 0.813rem;
}

.sv-fileshareall-portlet .sv-fileview-hits-sr {
   font-size: 0.688rem;
   font-weight: 600;
   position: absolute;
   bottom: 0;
   left: calc(0.5rem + 6px);
}

.sv-fileshareall-portlet .sv-filebrowse-search-container {
   position: relative;
   height: 3rem;
}

.modal-body textarea[data-text-content] {
   width: calc(100% - 15px);
   height: 350px;
}

@media only screen and (max-width:500px) {
   .sv-fileshareall-portlet .sv-file-selection,
   .sv-fileshareall-portlet .sv-file-selection-heading,
   .sv-fileshareall-portlet .sv-file-version-actions,
   .sv-fileshareall-portlet .sv-file-version-actions-heading,
   .sv-fileshareall-portlet .sv-version-description,
   .sv-fileshareall-portlet .sv-file-size,
   .sv-fileshareall-portlet .sv-file-size-heading {
      display:none
   }

   .sv-fileshareall-portlet .sv-version-name a {
      text-decoration:underline
   }

   .sv-fileshareall-portlet .sv-file-browse-area {
      flex-direction: column;
   }
}

/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author sjostrand
 */

.dropdown-menu li a {
   text-decoration:none
}

.sv-social-contactsearch-name,
.sv-social-contactsearch-userfield {
   display:block !important;
   margin:0 !important;/* 0 0 45px;*/
   padding:0 !important;
   line-height:normal
}

.sv-contactsearch-form {
   position:relative
}

.sv-social-contactsearch-result {
    background-clip: padding-box;
    background-color: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px 6px 6px 6px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    left: 0;
    padding: 1px;
    position: absolute;
    top: 0;
    width: 236px;
    z-index: 1010
}
.sv-contactsearch-portlet form.sv-social-contactsearch-form {
   margin: .5em 0 .5em 0
}

.sv-contactsearch-portlet .sv-social-contactsearch-form input.search-query {
   height: 30px;
   box-sizing: border-box;
}

.sv-social-contactsearch-result {
   padding: 9px 0
}

.sv-social-contactsearch-result p, .sv-social-contactsearch-result ul, .sv-social-contactsearch-result ol {
   margin-bottom: 0
}

.sv-social-contactsearch-user-container {
   overflow: hidden;
   padding-left: 4px;
   vertical-align: top;
}

.sv-user-search-list li.sv-contactsearch-nohits {
   text-align:center;
   margin-bottom: 5px;
   cursor:default

}

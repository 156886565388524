/*!
 * Bootstrap v2.2.1
 *
 * Copyright 2012 Twitter, Inc
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Designed and built with all the love in the world @twitter by @mdo and @fat.
 */
.bootstrap .clearfix:after {
  display: table;
  content: "";
  clear: both;
}
.bootstrap .hide-text {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.bootstrap .input-block-level {
  display: block;
  width: 100%;
  min-height: 30px;
  box-sizing: border-box;
}
.bootstrap form {
  margin: 0 0 20px;
}
.bootstrap fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}
.bootstrap legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: 40px;
  color: #333333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}
.bootstrap legend small {
  font-size: 15px;
  color: #999999;
}
.bootstrap label,
.bootstrap input,
.bootstrap button,
.bootstrap select,
.bootstrap textarea {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}
.bootstrap input,
.bootstrap button,
.bootstrap select,
.bootstrap textarea {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.bootstrap label {
  display: block;
  margin-bottom: 5px;
}
.bootstrap select,
.bootstrap textarea,
.bootstrap input[type="text"],
.bootstrap input[type="password"],
.bootstrap input[type="datetime"],
.bootstrap input[type="datetime-local"],
.bootstrap input[type="date"],
.bootstrap input[type="month"],
.bootstrap input[type="time"],
.bootstrap input[type="week"],
.bootstrap input[type="number"],
.bootstrap input[type="email"],
.bootstrap input[type="url"],
.bootstrap input[type="search"],
.bootstrap input[type="tel"],
.bootstrap input[type="color"],
.bootstrap .uneditable-input {
  display: inline-block;
  height: 20px;
  padding: 4px 6px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #555555;
  border-radius: 4px;
  vertical-align: middle;
}
.bootstrap input,
.bootstrap textarea,
.bootstrap .uneditable-input {
  width: 206px;
}
.bootstrap textarea {
  height: auto;
}
.bootstrap textarea,
.bootstrap input[type="text"],
.bootstrap input[type="password"],
.bootstrap input[type="datetime"],
.bootstrap input[type="datetime-local"],
.bootstrap input[type="date"],
.bootstrap input[type="month"],
.bootstrap input[type="time"],
.bootstrap input[type="week"],
.bootstrap input[type="number"],
.bootstrap input[type="email"],
.bootstrap input[type="url"],
.bootstrap input[type="search"],
.bootstrap input[type="tel"],
.bootstrap input[type="color"],
.bootstrap .uneditable-input {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border linear .2s, box-shadow linear .2s;
}
.bootstrap textarea:focus,
.bootstrap input[type="text"]:focus,
.bootstrap input[type="password"]:focus,
.bootstrap input[type="datetime"]:focus,
.bootstrap input[type="datetime-local"]:focus,
.bootstrap input[type="date"]:focus,
.bootstrap input[type="month"]:focus,
.bootstrap input[type="time"]:focus,
.bootstrap input[type="week"]:focus,
.bootstrap input[type="number"]:focus,
.bootstrap input[type="email"]:focus,
.bootstrap input[type="url"]:focus,
.bootstrap input[type="search"]:focus,
.bootstrap input[type="tel"]:focus,
.bootstrap input[type="color"]:focus,
.bootstrap .uneditable-input:focus {
  border-color: rgba(82, 168, 236, 0.8);
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6);
}
.bootstrap input[type="radio"],
.bootstrap input[type="checkbox"] {
  margin: 4px 0 0;
  line-height: normal;
  cursor: pointer;
}
.bootstrap input[type="file"],
.bootstrap input[type="image"],
.bootstrap input[type="submit"],
.bootstrap input[type="reset"],
.bootstrap input[type="button"],
.bootstrap input[type="radio"],
.bootstrap input[type="checkbox"] {
  width: auto;
}
.bootstrap select,
.bootstrap input[type="file"] {
  height: 30px;
  line-height: 30px;
}
.bootstrap select {
  width: 220px;
  border: 1px solid #cccccc;
  background-color: #ffffff;
}
.bootstrap select[multiple],
.bootstrap select[size] {
  height: auto;
}
.bootstrap select:focus,
.bootstrap input[type="file"]:focus,
.bootstrap input[type="radio"]:focus,
.bootstrap input[type="checkbox"]:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.bootstrap .uneditable-input,
.bootstrap .uneditable-textarea {
  color: #999999;
  background-color: #fcfcfc;
  border-color: #cccccc;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.025);
  cursor: not-allowed;
}
.bootstrap .uneditable-input {
  overflow: hidden;
  white-space: nowrap;
}
.bootstrap .uneditable-textarea {
  width: auto;
  height: auto;
}
.bootstrap input::placeholder, .bootstrap textarea::placeholder {
  color: #999999; 
}
.bootstrap .radio,
.bootstrap .checkbox {
  min-height: 20px;
  padding-left: 20px;
}
.bootstrap .radio input[type="radio"],
.bootstrap .checkbox input[type="checkbox"] {
  float: left;
  margin-left: -20px;
}
.bootstrap .controls > .radio:first-child,
.bootstrap .controls > .checkbox:first-child {
  padding-top: 5px;
}
.bootstrap .radio.inline,
.bootstrap .checkbox.inline {
  display: inline-block;
  padding-top: 5px;
  margin-bottom: 0;
  vertical-align: middle;
}
.bootstrap .radio.inline + .radio.inline,
.bootstrap .checkbox.inline + .checkbox.inline {
  margin-left: 10px;
}
.bootstrap .input-mini {
  width: 60px;
}
.bootstrap .input-small {
  width: 90px;
}
.bootstrap .input-medium {
  width: 150px;
}
.bootstrap .input-large {
  width: 210px;
}
.bootstrap .input-xlarge {
  width: 270px;
}
.bootstrap .input-xxlarge {
  width: 530px;
}
.bootstrap input[class*="span"],
.bootstrap select[class*="span"],
.bootstrap textarea[class*="span"],
.bootstrap .uneditable-input[class*="span"],
.bootstrap .row-fluid input[class*="span"],
.bootstrap .row-fluid select[class*="span"],
.bootstrap .row-fluid textarea[class*="span"],
.bootstrap .row-fluid .uneditable-input[class*="span"] {
  float: none;
  margin-left: 0;
}
.bootstrap .input-append input[class*="span"],
.bootstrap .input-append .uneditable-input[class*="span"],
.bootstrap .input-prepend input[class*="span"],
.bootstrap .input-prepend .uneditable-input[class*="span"],
.bootstrap .row-fluid input[class*="span"],
.bootstrap .row-fluid select[class*="span"],
.bootstrap .row-fluid textarea[class*="span"],
.bootstrap .row-fluid .uneditable-input[class*="span"],
.bootstrap .row-fluid .input-prepend [class*="span"],
.bootstrap .row-fluid .input-append [class*="span"] {
  display: inline-block;
}
.bootstrap input,
.bootstrap textarea,
.bootstrap .uneditable-input {
  margin-left: 0;
}
.bootstrap .controls-row [class*="span"] + [class*="span"] {
  margin-left: 20px;
}
.bootstrap input.span12, textarea.span12, .uneditable-input.span12 {
  width: 926px;
}
.bootstrap input.span11, textarea.span11, .uneditable-input.span11 {
  width: 846px;
}
.bootstrap input.span10, textarea.span10, .uneditable-input.span10 {
  width: 766px;
}
.bootstrap input.span9, textarea.span9, .uneditable-input.span9 {
  width: 686px;
}
.bootstrap input.span8, textarea.span8, .uneditable-input.span8 {
  width: 606px;
}
.bootstrap input.span7, textarea.span7, .uneditable-input.span7 {
  width: 526px;
}
.bootstrap input.span6, textarea.span6, .uneditable-input.span6 {
  width: 446px;
}
.bootstrap input.span5, textarea.span5, .uneditable-input.span5 {
  width: 366px;
}
.bootstrap input.span4, textarea.span4, .uneditable-input.span4 {
  width: 286px;
}
.bootstrap input.span3, textarea.span3, .uneditable-input.span3 {
  width: 206px;
}
.bootstrap input.span2, textarea.span2, .uneditable-input.span2 {
  width: 126px;
}
.bootstrap input.span1, textarea.span1, .uneditable-input.span1 {
  width: 46px;
}
.bootstrap .controls-row:before,
.bootstrap .controls-row:after {
  display: table;
  content: "";
  line-height: 0;
}
.bootstrap .controls-row:after {
  clear: both;
}
.bootstrap .controls-row [class*="span"],
.bootstrap .row-fluid .controls-row [class*="span"] {
  float: left;
}
.bootstrap .controls-row .checkbox[class*="span"],
.bootstrap .controls-row .radio[class*="span"] {
  padding-top: 5px;
}
.bootstrap input[disabled],
.bootstrap select[disabled],
.bootstrap textarea[disabled],
.bootstrap input[readonly],
.bootstrap select[readonly],
.bootstrap textarea[readonly] {
  cursor: not-allowed;
  background-color: #eeeeee;
}
.bootstrap input[type="radio"][disabled],
.bootstrap input[type="checkbox"][disabled],
.bootstrap input[type="radio"][readonly],
.bootstrap input[type="checkbox"][readonly] {
  background-color: transparent;
}
.bootstrap .control-group.warning > label,
.bootstrap .control-group.warning .help-block,
.bootstrap .control-group.warning .help-inline {
  color: #c09853;
}
.bootstrap .control-group.warning .checkbox,
.bootstrap .control-group.warning .radio,
.bootstrap .control-group.warning input,
.bootstrap .control-group.warning select,
.bootstrap .control-group.warning textarea {
  color: #c09853;
}
.bootstrap .control-group.warning input,
.bootstrap .control-group.warning select,
.bootstrap .control-group.warning textarea {
  border-color: #c09853;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.bootstrap .control-group.warning input:focus,
.bootstrap .control-group.warning select:focus,
.bootstrap .control-group.warning textarea:focus {
  border-color: #a47e3c;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #dbc59e;
}
.bootstrap .control-group.warning .input-prepend .add-on,
.bootstrap .control-group.warning .input-append .add-on {
  color: #c09853;
  background-color: #fcf8e3;
  border-color: #c09853;
}
.bootstrap .control-group.error > label,
.bootstrap .control-group.error .help-block,
.bootstrap .control-group.error .help-inline {
  color: #b94a48;
}
.bootstrap .control-group.error .checkbox,
.bootstrap .control-group.error .radio,
.bootstrap .control-group.error input,
.bootstrap .control-group.error select,
.bootstrap .control-group.error textarea {
  color: #b94a48;
}
.bootstrap .control-group.error input,
.bootstrap .control-group.error select,
.bootstrap .control-group.error textarea {
  border-color: #b94a48;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.bootstrap .control-group.error input:focus,
.bootstrap .control-group.error select:focus,
.bootstrap .control-group.error textarea:focus {
  border-color: #953b39;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #d59392;
}
.bootstrap .control-group.error .input-prepend .add-on,
.bootstrap .control-group.error .input-append .add-on {
  color: #b94a48;
  background-color: #f2dede;
  border-color: #b94a48;
}
.bootstrap .control-group.success > label,
.bootstrap .control-group.success .help-block,
.bootstrap .control-group.success .help-inline {
  color: #468847;
}
.bootstrap .control-group.success .checkbox,
.bootstrap .control-group.success .radio,
.bootstrap .control-group.success input,
.bootstrap .control-group.success select,
.bootstrap .control-group.success textarea {
  color: #468847;
}
.bootstrap .control-group.success input,
.bootstrap .control-group.success select,
.bootstrap .control-group.success textarea {
  border-color: #468847;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.bootstrap .control-group.success input:focus,
.bootstrap .control-group.success select:focus,
.bootstrap .control-group.success textarea:focus {
  border-color: #356635;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7aba7b;
}
.bootstrap .control-group.success .input-prepend .add-on,
.bootstrap .control-group.success .input-append .add-on {
  color: #468847;
  background-color: #dff0d8;
  border-color: #468847;
}
.bootstrap .control-group.info > label,
.bootstrap .control-group.info .help-block,
.bootstrap .control-group.info .help-inline {
  color: #3a87ad;
}
.bootstrap .control-group.info .checkbox,
.bootstrap .control-group.info .radio,
.bootstrap .control-group.info input,
.bootstrap .control-group.info select,
.bootstrap .control-group.info textarea {
  color: #3a87ad;
}
.bootstrap .control-group.info input,
.bootstrap .control-group.info select,
.bootstrap .control-group.info textarea {
  border-color: #3a87ad;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.bootstrap .control-group.info input:focus,
.bootstrap .control-group.info select:focus,
.bootstrap .control-group.info textarea:focus {
  border-color: #2d6987;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7ab5d3;
}
.bootstrap .control-group.info .input-prepend .add-on,
.bootstrap .control-group.info .input-append .add-on {
  color: #3a87ad;
  background-color: #d9edf7;
  border-color: #3a87ad;
}
.bootstrap input:focus:required:invalid,
.bootstrap textarea:focus:required:invalid,
.bootstrap select:focus:required:invalid {
  color: #b94a48;
  border-color: #ee5f5b;
}
.bootstrap input:focus:required:invalid:focus,
.bootstrap textarea:focus:required:invalid:focus,
.bootstrap select:focus:required:invalid:focus {
  border-color: #e9322d;
  box-shadow: 0 0 6px #f8b9b7;
}
.bootstrap .form-actions {
  padding: 19px 20px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border-top: 1px solid #e5e5e5;
}
.bootstrap .form-actions:before,
.bootstrap .form-actions:after {
  display: table;
  content: "";
  line-height: 0;
}
.bootstrap .form-actions:after {
  clear: both;
}
.bootstrap .help-block,
.bootstrap .help-inline {
  color: #595959;
  font-style:italic
}
.bootstrap .help-block {
  display: block;
  margin-bottom: 10px;
}
.bootstrap .help-inline {
  display: inline-block;
  vertical-align: middle;
  padding-left: 5px;
}
.bootstrap .input-append,
.bootstrap .input-prepend {
  margin-bottom: 5px;
  font-size: 0;
  white-space: nowrap;
}
.bootstrap .input-append input,
.bootstrap .input-prepend input,
.bootstrap .input-append select,
.bootstrap .input-prepend select,
.bootstrap .input-append .uneditable-input,
.bootstrap .input-prepend .uneditable-input,
.bootstrap .input-append .dropdown-menu,
.bootstrap .input-prepend .dropdown-menu {
  font-size: 14px;
}
.bootstrap .input-append input,
.bootstrap .input-prepend input,
.bootstrap .input-append select,
.bootstrap .input-prepend select,
.bootstrap .input-append .uneditable-input,
.bootstrap .input-prepend .uneditable-input {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
  border-radius: 0 4px 4px 0;
}
.bootstrap .input-append input:focus,
.bootstrap .input-prepend input:focus,
.bootstrap .input-append select:focus,
.bootstrap .input-prepend select:focus,
.bootstrap .input-append .uneditable-input:focus,
.bootstrap .input-prepend .uneditable-input:focus {
  z-index: 2;
}
.bootstrap .input-append .add-on,
.bootstrap .input-prepend .add-on {
  display: inline-block;
  width: auto;
  height: 20px;
  min-width: 16px;
  padding: 4px 5px;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  text-align: center;
  text-shadow: 0 1px 0 #ffffff;
  background-color: #eeeeee;
  border: 1px solid #ccc;
}
.bootstrap .input-append .add-on,
.bootstrap .input-prepend .add-on,
.bootstrap .input-append .btn,
.bootstrap .input-prepend .btn {
  vertical-align: top;
  border-radius: 0;
}
.bootstrap .input-append .active,
.bootstrap .input-prepend .active {
  background-color: #a9dba9;
  border-color: #46a546;
}
.bootstrap .input-prepend .add-on,
.bootstrap .input-prepend .btn {
  margin-right: -1px;
}
.bootstrap .input-prepend .add-on:first-child,
.bootstrap .input-prepend .btn:first-child {
  border-radius: 4px 0 0 4px;
}
.bootstrap .input-append input,
.bootstrap .input-append select,
.bootstrap .input-append .uneditable-input {
  border-radius: 4px 0 0 4px;
}
.bootstrap .input-append input + .btn-group .btn,
.bootstrap .input-append select + .btn-group .btn,
.bootstrap .input-append .uneditable-input + .btn-group .btn {
  border-radius: 0 4px 4px 0;
}
.bootstrap .input-append .add-on,
.bootstrap .input-append .btn,
.bootstrap .input-append .btn-group {
  margin-left: -1px;
}
.bootstrap .input-append .add-on:last-child,
.bootstrap .input-append .btn:last-child {
  border-radius: 0 4px 4px 0;
}
.bootstrap .input-prepend.input-append input,
.bootstrap .input-prepend.input-append select,
.bootstrap .input-prepend.input-append .uneditable-input {
  border-radius: 0;
}
.bootstrap .input-prepend.input-append input + .btn-group .btn,
.bootstrap .input-prepend.input-append select + .btn-group .btn,
.bootstrap .input-prepend.input-append .uneditable-input + .btn-group .btn {
  border-radius: 0 4px 4px 0;
}
.bootstrap .input-prepend.input-append .add-on:first-child,
.bootstrap .input-prepend.input-append .btn:first-child {
  margin-right: -1px;
  border-radius: 4px 0 0 4px;
}
.bootstrap .input-prepend.input-append .add-on:last-child,
.bootstrap .input-prepend.input-append .btn:last-child {
  margin-left: -1px;
  border-radius: 0 4px 4px 0;
}
.bootstrap .input-prepend.input-append .btn-group:first-child {
  margin-left: 0;
}
.bootstrap input.search-query {
  padding-right: 14px;
  padding-left: 14px;

  margin-bottom: 0;
  border-radius: 15px;
}
.bootstrap .form-search .input-append .search-query,
.bootstrap .form-search .input-prepend .search-query {
  border-radius: 0;
}
.bootstrap .form-search .input-append .search-query {
  border-radius: 14px 0 0 14px;
}
.bootstrap .form-search .input-append .btn {
  border-radius: 0 14px 14px 0;
}
.bootstrap .form-search .input-prepend .search-query {
  border-radius: 0 14px 14px 0;
}
.bootstrap .form-search .input-prepend .btn {
  border-radius: 14px 0 0 14px;
}
.bootstrap .form-search input,
.bootstrap .form-inline input,
.bootstrap .form-horizontal input,
.bootstrap .form-search textarea,
.bootstrap .form-inline textarea,
.bootstrap .form-horizontal textarea,
.bootstrap .form-search select,
.bootstrap .form-inline select,
.bootstrap .form-horizontal select,
.bootstrap .form-search .help-inline,
.bootstrap .form-inline .help-inline,
.bootstrap .form-horizontal .help-inline,
.bootstrap .form-search .uneditable-input,
.bootstrap .form-inline .uneditable-input,
.bootstrap .form-horizontal .uneditable-input,
.bootstrap .form-search .input-prepend,
.bootstrap .form-inline .input-prepend,
.bootstrap .form-horizontal .input-prepend,
.bootstrap .form-search .input-append,
.bootstrap .form-inline .input-append,
.bootstrap .form-horizontal .input-append {
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
}
.bootstrap .form-search .hide,
.bootstrap .form-inline .hide,
.bootstrap .form-horizontal .hide {
  display: none;
}
.bootstrap .form-search label,
.bootstrap .form-inline label,
.bootstrap .form-search .btn-group,
.bootstrap .form-inline .btn-group {
  display: inline-block;
}
.bootstrap .form-search .input-append,
.bootstrap .form-inline .input-append,
.bootstrap .form-search .input-prepend,
.bootstrap .form-inline .input-prepend {
  margin-bottom: 0;
}
.bootstrap .form-search .radio,
.bootstrap .form-search .checkbox,
.bootstrap .form-inline .radio,
.bootstrap .form-inline .checkbox {
  padding-left: 0;
  margin-bottom: 0;
  vertical-align: middle;
}
.bootstrap .form-search .radio input[type="radio"],
.bootstrap .form-search .checkbox input[type="checkbox"],
.bootstrap .form-inline .radio input[type="radio"],
.bootstrap .form-inline .checkbox input[type="checkbox"] {
  float: left;
  margin-right: 3px;
  margin-left: 0;
}
.bootstrap .control-group {
  margin-bottom: 10px;
}
.bootstrap legend + .control-group {
  margin-top: 20px;
}
.bootstrap .form-horizontal .control-group {
  margin-bottom: 20px;
}
.bootstrap .form-horizontal .control-group:before,
.bootstrap .form-horizontal .control-group:after {
  display: table;
  content: "";
  line-height: 0;
}
.bootstrap .form-horizontal .control-group:after {
  clear: both;
}
.bootstrap .form-horizontal .control-label {
  float: left;
  width: 160px;
  padding-top: 5px;
  text-align: right;
}
.bootstrap .form-horizontal .controls {
  margin-left: 180px;
}

.bootstrap .form-horizontal .help-block {
  margin-bottom: 0;
}
.bootstrap .form-horizontal input + .help-block,
.bootstrap .form-horizontal select + .help-block,
.bootstrap .form-horizontal textarea + .help-block {
  margin-top: 10px;
}
.bootstrap .form-horizontal .form-actions {
  padding-left: 180px;
}
.bootstrap [class^="icon-"],
.bootstrap [class*=" icon-"] {
  display: inline-block;
  width: 14px;
  height: 14px;
  line-height: 14px;
  vertical-align: text-top;
  background-image: url("../../glyphicons/img/glyphicons-halflings.png");
  background-position: 14px 14px;
  background-repeat: no-repeat;
  margin-top: 1px;
}
.bootstrap .icon-white,
.bootstrap .nav-pills > .active > a > [class^="icon-"],
.bootstrap .nav-pills > .active > a > [class*=" icon-"],
.bootstrap .nav-list > .active > a > [class^="icon-"],
.bootstrap .nav-list > .active > a > [class*=" icon-"],
.bootstrap .navbar-inverse .nav > .active > a > [class^="icon-"],
.bootstrap .navbar-inverse .nav > .active > a > [class*=" icon-"],
.bootstrap .dropdown-menu > li > a:hover > [class^="icon-"],
.bootstrap .dropdown-menu > li > a:hover > [class*=" icon-"],
.bootstrap .dropdown-menu > .active > a > [class^="icon-"],
.bootstrap .dropdown-menu > .active > a > [class*=" icon-"],
.bootstrap .dropdown-submenu:hover > a > [class^="icon-"],
.bootstrap .dropdown-submenu:hover > a > [class*=" icon-"] {
  background-image: url("../../glyphicons/img/glyphicons-halflings-white.png");
}
.bootstrap .icon-glass {
  background-position: 0      0;
}
.bootstrap .icon-music {
  background-position: -24px 0;
}
.bootstrap .icon-search {
  background-position: -48px 0;
}
.bootstrap .icon-envelope {
  background-position: -72px 0;
}
.bootstrap .icon-heart {
  background-position: -96px 0;
}
.bootstrap .icon-star {
  background-position: -120px 0;
}
.bootstrap .icon-star-empty {
  background-position: -144px 0;
}
.bootstrap .icon-user {
  background-position: -168px 0;
}
.bootstrap .icon-film {
  background-position: -192px 0;
}
.bootstrap .icon-th-large {
  background-position: -216px 0;
}
.bootstrap .icon-th {
  background-position: -240px 0;
}
.bootstrap .icon-th-list {
  background-position: -264px 0;
}
.bootstrap .icon-ok {
  background-position: -288px 0;
}
.bootstrap .icon-remove {
  background-position: -312px 0;
}
.bootstrap .icon-zoom-in {
  background-position: -336px 0;
}
.bootstrap .icon-zoom-out {
  background-position: -360px 0;
}
.bootstrap .icon-off {
  background-position: -384px 0;
}
.bootstrap .icon-signal {
  background-position: -408px 0;
}
.bootstrap .icon-cog {
  background-position: -432px 0;
}
.bootstrap .icon-trash {
  background-position: -456px 0;
}
.bootstrap .icon-home {
  background-position: 0 -24px;
}
.bootstrap .icon-file {
  background-position: -24px -24px;
}
.bootstrap .icon-time {
  background-position: -48px -24px;
}
.bootstrap .icon-road {
  background-position: -72px -24px;
}
.bootstrap .icon-download-alt {
  background-position: -96px -24px;
}
.bootstrap .icon-download {
  background-position: -120px -24px;
}
.bootstrap .icon-upload {
  background-position: -144px -24px;
}
.bootstrap .icon-inbox {
  background-position: -168px -24px;
}
.bootstrap .icon-play-circle {
  background-position: -192px -24px;
}
.bootstrap .icon-repeat {
  background-position: -216px -24px;
}
.bootstrap .icon-refresh {
  background-position: -240px -24px;
}
.bootstrap .icon-list-alt {
  background-position: -264px -24px;
}
.bootstrap .icon-lock {
  background-position: -287px -24px;
}
.bootstrap .icon-flag {
  background-position: -312px -24px;
}
.bootstrap .icon-headphones {
  background-position: -336px -24px;
}
.bootstrap .icon-volume-off {
  background-position: -360px -24px;
}
.bootstrap .icon-volume-down {
  background-position: -384px -24px;
}
.bootstrap .icon-volume-up {
  background-position: -408px -24px;
}
.bootstrap .icon-qrcode {
  background-position: -432px -24px;
}
.bootstrap .icon-barcode {
  background-position: -456px -24px;
}
.bootstrap .icon-tag {
  background-position: 0 -48px;
}
.bootstrap .icon-tags {
  background-position: -25px -48px;
}
.bootstrap .icon-book {
  background-position: -48px -48px;
}
.bootstrap .icon-bookmark {
  background-position: -72px -48px;
}
.bootstrap .icon-print {
  background-position: -96px -48px;
}
.bootstrap .icon-camera {
  background-position: -120px -48px;
}
.bootstrap .icon-font {
  background-position: -144px -48px;
}
.bootstrap .icon-bold {
  background-position: -167px -48px;
}
.bootstrap .icon-italic {
  background-position: -192px -48px;
}
.bootstrap .icon-text-height {
  background-position: -216px -48px;
}
.bootstrap .icon-text-width {
  background-position: -240px -48px;
}
.bootstrap .icon-align-left {
  background-position: -264px -48px;
}
.bootstrap .icon-align-center {
  background-position: -288px -48px;
}
.bootstrap .icon-align-right {
  background-position: -312px -48px;
}
.bootstrap .icon-align-justify {
  background-position: -336px -48px;
}
.bootstrap .icon-list {
  background-position: -360px -48px;
}
.bootstrap .icon-indent-left {
  background-position: -384px -48px;
}
.bootstrap .icon-indent-right {
  background-position: -408px -48px;
}
.bootstrap .icon-facetime-video {
  background-position: -432px -48px;
}
.bootstrap .icon-picture {
  background-position: -456px -48px;
}
.bootstrap .icon-pencil {
  background-position: 0 -72px;
}
.bootstrap .icon-map-marker {
  background-position: -24px -72px;
}
.bootstrap .icon-adjust {
  background-position: -48px -72px;
}
.bootstrap .icon-tint {
  background-position: -72px -72px;
}
.bootstrap .icon-edit {
  background-position: -96px -72px;
}
.bootstrap .icon-share {
  background-position: -120px -72px;
}
.bootstrap .icon-check {
  background-position: -144px -72px;
}
.bootstrap .icon-move {
  background-position: -168px -72px;
}
.bootstrap .icon-step-backward {
  background-position: -192px -72px;
}
.bootstrap .icon-fast-backward {
  background-position: -216px -72px;
}
.bootstrap .icon-backward {
  background-position: -240px -72px;
}
.bootstrap .icon-play {
  background-position: -264px -72px;
}
.bootstrap .icon-pause {
  background-position: -288px -72px;
}
.bootstrap .icon-stop {
  background-position: -312px -72px;
}
.bootstrap .icon-forward {
  background-position: -336px -72px;
}
.bootstrap .icon-fast-forward {
  background-position: -360px -72px;
}
.bootstrap .icon-step-forward {
  background-position: -384px -72px;
}
.bootstrap .icon-eject {
  background-position: -408px -72px;
}
.bootstrap .icon-chevron-left {
  background-position: -432px -72px;
}
.bootstrap .icon-chevron-right {
  background-position: -456px -72px;
}
.bootstrap .icon-plus-sign {
  background-position: 0 -96px;
}
.bootstrap .icon-minus-sign {
  background-position: -24px -96px;
}
.bootstrap .icon-remove-sign {
  background-position: -48px -96px;
}
.bootstrap .icon-ok-sign {
  background-position: -72px -96px;
}
.bootstrap .icon-question-sign {
  background-position: -96px -96px;
}
.bootstrap .icon-info-sign {
  background-position: -120px -96px;
}
.bootstrap .icon-screenshot {
  background-position: -144px -96px;
}
.bootstrap .icon-remove-circle {
  background-position: -168px -96px;
}
.bootstrap .icon-ok-circle {
  background-position: -192px -96px;
}
.bootstrap .icon-ban-circle {
  background-position: -216px -96px;
}
.bootstrap .icon-arrow-left {
  background-position: -240px -96px;
}
.bootstrap .icon-arrow-right {
  background-position: -264px -96px;
}
.bootstrap .icon-arrow-up {
  background-position: -289px -96px;
}
.bootstrap .icon-arrow-down {
  background-position: -312px -96px;
}
.bootstrap .icon-share-alt {
  background-position: -336px -96px;
}
.bootstrap .icon-resize-full {
  background-position: -360px -96px;
}
.bootstrap .icon-resize-small {
  background-position: -384px -96px;
}
.bootstrap .icon-plus {
  background-position: -408px -96px;
}
.bootstrap .icon-minus {
  background-position: -433px -96px;
}
.bootstrap .icon-asterisk {
  background-position: -456px -96px;
}
.bootstrap .icon-exclamation-sign {
  background-position: 0 -120px;
}
.bootstrap .icon-gift {
  background-position: -24px -120px;
}
.bootstrap .icon-leaf {
  background-position: -48px -120px;
}
.bootstrap .icon-fire {
  background-position: -72px -120px;
}
.bootstrap .icon-eye-open {
  background-position: -96px -120px;
}
.bootstrap .icon-eye-close {
  background-position: -120px -120px;
}
.bootstrap .icon-warning-sign {
  background-position: -144px -120px;
}
.bootstrap .icon-plane {
  background-position: -168px -120px;
}
.bootstrap .icon-calendar {
  background-position: -192px -120px;
}
.bootstrap .icon-random {
  background-position: -216px -120px;
  width: 16px;
}
.bootstrap .icon-comment {
  background-position: -240px -120px;
}
.bootstrap .icon-magnet {
  background-position: -264px -120px;
}
.bootstrap .icon-chevron-up {
  background-position: -288px -120px;
}
.bootstrap .icon-chevron-down {
  background-position: -313px -119px;
}
.bootstrap .icon-retweet {
  background-position: -336px -120px;
}
.bootstrap .icon-shopping-cart {
  background-position: -360px -120px;
}
.bootstrap .icon-folder-close {
  background-position: -384px -120px;
}
.bootstrap .icon-folder-open {
  background-position: -408px -120px;
  width: 16px;
}
.bootstrap .icon-resize-vertical {
  background-position: -432px -119px;
}
.bootstrap .icon-resize-horizontal {
  background-position: -456px -118px;
}
.bootstrap .icon-hdd {
  background-position: 0 -144px;
}
.bootstrap .icon-bullhorn {
  background-position: -24px -144px;
}
.bootstrap .icon-bell {
  background-position: -48px -144px;
}
.bootstrap .icon-certificate {
  background-position: -72px -144px;
}
.bootstrap .icon-thumbs-up {
  background-position: -96px -144px;
}
.bootstrap .icon-thumbs-down {
  background-position: -120px -144px;
}
.bootstrap .icon-hand-right {
  background-position: -144px -144px;
}
.bootstrap .icon-hand-left {
  background-position: -168px -144px;
}
.bootstrap .icon-hand-up {
  background-position: -192px -144px;
}
.bootstrap .icon-hand-down {
  background-position: -216px -144px;
}
.bootstrap .icon-circle-arrow-right {
  background-position: -240px -144px;
}
.bootstrap .icon-circle-arrow-left {
  background-position: -264px -144px;
}
.bootstrap .icon-circle-arrow-up {
  background-position: -288px -144px;
}
.bootstrap .icon-circle-arrow-down {
  background-position: -312px -144px;
}
.bootstrap .icon-globe {
  background-position: -336px -144px;
}
.bootstrap .icon-wrench {
  background-position: -360px -144px;
}
.bootstrap .icon-tasks {
  background-position: -384px -144px;
}
.bootstrap .icon-filter {
  background-position: -408px -144px;
}
.bootstrap .icon-briefcase {
  background-position: -432px -144px;
}
.bootstrap .icon-fullscreen {
  background-position: -456px -144px;
}
.dropup,
.dropdown {
  position: relative;
}
.dropdown-toggle:active,
.open .dropdown-toggle {
  outline: 0;
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: text-top;
  border-top: 4px solid #000000;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
}
.dropdown .caret {
  margin-top: 8px;
  margin-left: 2px;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  *border-right-width: 2px;
  *border-bottom-width: 2px;
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
}
.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}
.dropdown-menu .divider {
  height: 1px;
  margin: 9px 1px;
  overflow: hidden;
  background-color: #e5e5e5;
  border-bottom: 1px solid #ffffff;
}
.dropdown-menu li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 20px;
  color: #333333;
  white-space: nowrap;
}
.dropdown-menu li > a:hover,
.dropdown-menu li > a:focus,
.dropdown-submenu:hover > a {
  text-decoration: none;
  color: #ffffff;
  background-color: #0081c2;
  background-image: linear-gradient(to bottom, #0088cc, #0077b3);
  background-repeat: repeat-x;
}
.dropdown-menu .active > a,
.dropdown-menu .active > a:hover {
  color: #333333;
  text-decoration: none;
  outline: 0;
  background-color: #0081c2;
  background-image: linear-gradient(to bottom, #0088cc, #0077b3);
  background-repeat: repeat-x;
}
.dropdown-menu .disabled > a,
.dropdown-menu .disabled > a:hover {
  color: #999999;
}
.dropdown-menu .disabled > a:hover {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  cursor: default;
}
.open > .dropdown-menu {
  display: block;
}
.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px solid #000000;
  content: "";
}
.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 1px;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  border-radius: 0 6px 6px 6px;
}
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.dropup .dropdown-submenu > .dropdown-menu {
  top: auto;
  bottom: 0;
  margin-top: 0;
  margin-bottom: -2px;
  border-radius: 5px 5px 5px 0;
}
.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #cccccc;
  margin-top: 5px;
  margin-right: -10px;
}
.dropdown-submenu:hover > a:after {
  border-left-color: #ffffff;
}
.dropdown-submenu.pull-left {
  float: none;
}
.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  border-radius: 6px 0 6px 6px;
}
.dropdown .dropdown-menu .nav-header {
  padding-left: 20px;
  padding-right: 20px;
}
.typeahead {
  margin-top: 2px;
  border-radius: 4px;
}
.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}
.fade.in {
  opacity: 1;
}
.collapse {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
.collapse.in {
  height: auto;
}
.bootstrap .well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.bootstrap .well blockquote {
  border-color: #ddd;
  border-color: rgba(0, 0, 0, 0.15);
}
.bootstrap .well-large {
  padding: 24px;
  border-radius: 6px;
}
.bootstrap .well-small {
  padding: 9px;
  border-radius: 3px;
}
.bootstrap .close {
  float: right;
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  color: #000000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.2;
  filter: alpha(opacity=20);
}
.bootstrap .close:hover {
  color: #000000;
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
  filter: alpha(opacity=40);
}
.bootstrap button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}
.bootstrap .btn {
  display: inline-block;
  padding: 4px 12px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  color: #333333;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  background-color: #f5f5f5;
  background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
  background-repeat: repeat-x;
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  border: 1px solid #bbbbbb;
  border-bottom-color: #a2a2a2;
  border-radius: 4px;
  *margin-left: .3em;
  box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
}
.bootstrap .btn:hover,
.bootstrap .btn:active,
.bootstrap .btn.active,
.bootstrap .btn.disabled,
.bootstrap .btn[disabled] {
  color: #333333;
  background-color: #e6e6e6;
}
.bootstrap .btn:hover {
  color: #333333;
  text-decoration: none;
  background-color: #e6e6e6;

  background-position: 0 -15px;
  transition: background-position 0.1s linear;
}
.bootstrap .btn:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.bootstrap .btn.active,
.bootstrap .btn:active {
  background-color: #e6e6e6;
  background-image: none;
  outline: 0;
  box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
}
.bootstrap .btn.disabled,
.bootstrap .btn[disabled] {
  cursor: default;
  background-color: #e6e6e6;
  background-image: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  box-shadow: none;
}
.bootstrap .btn-large {
  padding: 11px 19px;
  font-size: 17.5px;
  border-radius: 6px;
}
.bootstrap .btn-large [class^="icon-"],
.bootstrap .btn-large [class*=" icon-"] {
  margin-top: 2px;
}
.bootstrap .btn-small {
  padding: 2px 10px;
  font-size: 11.9px;
  border-radius: 3px;
}
.bootstrap .btn-small [class^="icon-"],
.bootstrap .btn-small [class*=" icon-"] {
  margin-top: 0;
}
.bootstrap .btn-mini {
  padding: 1px 6px;
  font-size: 10.5px;
  border-radius: 3px;
}
.bootstrap .btn-block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  box-sizing: border-box;
}
.bootstrap .btn-block + .btn-block {
  margin-top: 5px;
}
.bootstrap input[type="submit"].btn-block,
.bootstrap input[type="reset"].btn-block,
.bootstrap input[type="button"].btn-block {
  width: 100%;
}
.bootstrap .btn-primary.active,
.bootstrap .btn-warning.active,
.bootstrap .btn-danger.active,
.bootstrap .btn-success.active,
.bootstrap .btn-info.active,
.bootstrap .btn-inverse.active {
  color: rgba(255, 255, 255, 0.75);
}
.bootstrap .btn {
  border-color: #c5c5c5;
  border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.25);
}
.bootstrap .btn-primary {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #006dcc;
  background-image: linear-gradient(to bottom, #0088cc, #0044cc);
  background-repeat: repeat-x;
  border-color: #0044cc #0044cc #002a80;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
}
.bootstrap .btn-primary:hover,
.bootstrap .btn-primary:active,
.bootstrap .btn-primary.active,
.bootstrap .btn-primary.disabled,
.bootstrap .btn-primary[disabled] {
  color: #ffffff;
  background-color: #0044cc;
}
.bootstrap .btn-warning {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #faa732;
  background-image: linear-gradient(to bottom, #fbb450, #f89406);
  background-repeat: repeat-x;
  border-color: #f89406 #f89406 #ad6704;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);

}
.bootstrap .btn-warning:hover,
.bootstrap .btn-warning:active,
.bootstrap .btn-warning.active,
.bootstrap .btn-warning.disabled,
.bootstrap .btn-warning[disabled] {
  color: #ffffff;
  background-color: #f89406;
}
.bootstrap .btn-danger {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #da4f49;
  background-image: linear-gradient(to bottom, #ee5f5b, #bd362f);
  background-repeat: repeat-x;
  border-color: #bd362f #bd362f #802420;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);

}
.bootstrap .btn-danger:hover,
.bootstrap .btn-danger:active,
.bootstrap .btn-danger.active,
.bootstrap .btn-danger.disabled,
.bootstrap .btn-danger[disabled] {
  color: #ffffff;
  background-color: #bd362f;
}
.bootstrap .btn-success {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #5bb75b;
  background-image: linear-gradient(to bottom, #62c462, #51a351);
  background-repeat: repeat-x;
  border-color: #51a351 #51a351 #387038;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);

}
.bootstrap .btn-success:hover,
.bootstrap .btn-success:active,
.bootstrap .btn-success.active,
.bootstrap .btn-success.disabled,
.bootstrap .btn-success[disabled] {
  color: #ffffff;
  background-color: #51a351;
}
.bootstrap .btn-info {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #49afcd;
  background-image: linear-gradient(to bottom, #5bc0de, #2f96b4);
  background-repeat: repeat-x;
  border-color: #2f96b4 #2f96b4 #1f6377;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);

}
.bootstrap .btn-info:hover,
.bootstrap .btn-info:active,
.bootstrap .btn-info.active,
.bootstrap .btn-info.disabled,
.bootstrap .btn-info[disabled] {
  color: #ffffff;
  background-color: #2f96b4;
}
.bootstrap .btn-inverse {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #363636;
  background-image: linear-gradient(to bottom, #444444, #222222);
  background-repeat: repeat-x;
  border-color: #222222 #222222 #000000;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);

}
.bootstrap .btn-inverse:hover,
.bootstrap .btn-inverse:active,
.bootstrap .btn-inverse.active,
.bootstrap .btn-inverse.disabled,
.bootstrap .btn-inverse[disabled] {
  color: #ffffff;
  background-color: #222222;
}
.bootstrap button.btn.btn-large,
.bootstrap input[type="submit"].btn.btn-large {
  *padding-top: 7px;
  *padding-bottom: 7px;
}
.bootstrap button.btn.btn-small,
.bootstrap input[type="submit"].btn.btn-small {
  *padding-top: 3px;
  *padding-bottom: 3px;
}
.bootstrap button.btn.btn-mini,
.bootstrap input[type="submit"].btn.btn-mini {
  *padding-top: 1px;
  *padding-bottom: 1px;
}
.bootstrap .btn-link,
.bootstrap .btn-link:active,
.bootstrap .btn-link[disabled] {
  background-color: transparent;
  background-image: none;
  box-shadow: none;
}
.bootstrap .btn-link {
  border-color: transparent;
  cursor: pointer;
  color: #0088cc;
  border-radius: 0;
}
.bootstrap .btn-link:hover {
  color: #005580;
  text-decoration: underline;
  background-color: transparent;
}
.bootstrap .btn-link[disabled]:hover {
  color: #333333;
  text-decoration: none;
}
.bootstrap .btn-group {
  position: relative;
  display: inline-block;
  font-size: 0;
  vertical-align: middle;
  white-space: nowrap;
}
.bootstrap .btn-group + .btn-group {
  margin-left: 5px;
}
.bootstrap .btn-toolbar {
  font-size: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}
.bootstrap .btn-toolbar .btn + .btn,
.bootstrap .btn-toolbar .btn-group + .btn,
.bootstrap .btn-toolbar .btn + .btn-group {
  margin-left: 5px;
}
.bootstrap .btn-group > .btn {
  position: relative;
  border-radius: 0;
}
.bootstrap .btn-group > .btn + .btn {
  margin-left: -1px;
}
.bootstrap .btn-group > .btn,
.bootstrap .btn-group > .dropdown-menu {
  font-size: 14px;
}
.bootstrap .btn-group > .btn-mini {
  font-size: 11px;
}
.bootstrap .btn-group > .btn-small {
  font-size: 12px;
}
.bootstrap .btn-group > .btn-large {
  font-size: 16px;
}
.bootstrap .btn-group > .btn:first-child {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.bootstrap .btn-group > .btn:last-child,
.bootstrap .btn-group > .dropdown-toggle {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.bootstrap .btn-group > .btn.large:first-child {
  margin-left: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.bootstrap .btn-group > .btn.large:last-child,
.bootstrap .btn-group > .large.dropdown-toggle {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.bootstrap .btn-group > .btn:hover,
.bootstrap .btn-group > .btn:focus,
.bootstrap .btn-group > .btn:active,
.bootstrap .btn-group > .btn.active {
  z-index: 2;
}
.bootstrap .btn-group .dropdown-toggle:active,
.bootstrap .btn-group.open .dropdown-toggle {
  outline: 0;
}
.bootstrap .btn-group > .btn + .dropdown-toggle {
  padding-left: 8px;
  padding-right: 8px;
  box-shadow: inset 1px 0 0 rgba(255,255,255,.125), inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
}
.bootstrap .btn-group > .btn-mini + .dropdown-toggle {
  padding-left: 5px;
  padding-right: 5px;
}
.bootstrap .btn-group > .btn-large + .dropdown-toggle {
  padding-left: 12px;
  padding-right: 12px;
}
.bootstrap .btn-group.open .dropdown-toggle {
  background-image: none;
  box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
}
.bootstrap .btn-group.open .btn.dropdown-toggle {
  background-color: #e6e6e6;
}
.bootstrap .btn-group.open .btn-primary.dropdown-toggle {
  background-color: #0044cc;
}
.bootstrap .btn-group.open .btn-warning.dropdown-toggle {
  background-color: #f89406;
}
.bootstrap .btn-group.open .btn-danger.dropdown-toggle {
  background-color: #bd362f;
}
.bootstrap .btn-group.open .btn-success.dropdown-toggle {
  background-color: #51a351;
}
.bootstrap .btn-group.open .btn-info.dropdown-toggle {
  background-color: #2f96b4;
}
.bootstrap .btn-group.open .btn-inverse.dropdown-toggle {
  background-color: #222222;
}
.bootstrap .btn .caret {
  margin-top: 8px;
  margin-left: 0;
}
.bootstrap .btn-mini .caret,
.bootstrap .btn-small .caret,
.bootstrap .btn-large .caret {
  margin-top: 6px;
}
.bootstrap .btn-large .caret {
  border-left-width: 5px;
  border-right-width: 5px;
  border-top-width: 5px;
}
.bootstrap .dropup .btn-large .caret {
  border-bottom-width: 5px;
}
.bootstrap .btn-primary .caret,
.bootstrap .btn-warning .caret,
.bootstrap .btn-danger .caret,
.bootstrap .btn-info .caret,
.bootstrap .btn-success .caret,
.bootstrap .btn-inverse .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
}
.bootstrap .btn-group-vertical {
  display: inline-block;
}
.bootstrap .btn-group-vertical .btn {
  display: block;
  float: none;
  width: 100%;
  border-radius: 0;
}
.bootstrap .btn-group-vertical .btn + .btn {
  margin-left: 0;
  margin-top: -1px;
}
.bootstrap .btn-group-vertical .btn:first-child {
  border-radius: 4px 4px 0 0;
}
.bootstrap .btn-group-vertical .btn:last-child {
  border-radius: 0 0 4px 4px;
}
.bootstrap .btn-group-vertical .btn-large:first-child {
  border-radius: 6px 6px 0 0;
}
.bootstrap .btn-group-vertical .btn-large:last-child {
  border-radius: 0 0 6px 6px;
}
.bootstrap .alert {
  padding: 8px 35px 8px 14px;
  margin-bottom: 20px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  background-color: #fcf8e3;
  border: 1px solid #fbeed5;
  border-radius: 4px;
  color: #c09853;
}
.bootstrap .alert h4 {
  margin: 0;
}
.bootstrap .alert .close {
  position: relative;
  top: -2px;
  right: -21px;
  line-height: 20px;
}
.bootstrap .alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #468847;
}
.bootstrap .alert-danger,
.bootstrap .alert-error {
  background-color: #f2dede;
  border-color: #eed3d7;
  color: #b94a48;
}
.bootstrap .alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #3a87ad;
}
.bootstrap .alert-block {
  padding-top: 14px;
  padding-bottom: 14px;
}
.bootstrap .alert-block > p,
.bootstrap .alert-block > ul {
  margin-bottom: 0;
}
.bootstrap .alert-block p + p {
  margin-top: 5px;
}
.bootstrap .nav {
  margin-left: 0;
  margin-bottom: 20px;
  list-style: none;
}
.bootstrap .nav > li > a {
  display: block;
}
.bootstrap .nav > li > a:hover {
  text-decoration: none;
  background-color: #eeeeee;
}
.bootstrap .nav > .pull-right {
  float: right;
}
.bootstrap .nav-header {
  display: block;
  padding: 3px 15px;
  font-size: 11px;
  font-weight: bold;
  line-height: 20px;
  color: #999999;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
}
.bootstrap .nav li + .nav-header {
  margin-top: 9px;
}
.bootstrap .nav-list {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0;
}
.bootstrap .nav-list > li > a,
.bootstrap .nav-list .nav-header {
  margin-left: -15px;
  margin-right: -15px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}
.bootstrap .nav-list > li > a {
  padding: 3px 15px;
}
.bootstrap .nav-list > .active > a,
.bootstrap .nav-list > .active > a:hover {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  background-color: #0088cc;
}
.bootstrap .nav-list [class^="icon-"],
.bootstrap .nav-list [class*=" icon-"] {
  margin-right: 2px;
}
.bootstrap .nav-list .divider {
  height: 1px;
  margin: 9px 1px;
  overflow: hidden;
  background-color: #e5e5e5;
  border-bottom: 1px solid #ffffff;
}
.bootstrap .nav-tabs:before,
.bootstrap .nav-pills:before,
.bootstrap .nav-tabs:after,
.bootstrap .nav-pills:after {
  display: table;
  content: "";
  line-height: 0;
}
.bootstrap .nav-tabs:after,
.bootstrap .nav-pills:after {
  clear: both;
}
.bootstrap .nav-tabs > li,
.bootstrap .nav-pills > li {
  float: left;
}
.bootstrap .nav-tabs > li > a,
.bootstrap .nav-pills > li > a {
  padding-right: 12px;
  padding-left: 12px;
  margin-right: 2px;
  line-height: 14px;
}
.bootstrap .nav-tabs {
  border-bottom: 1px solid #ddd;
}
.bootstrap .nav-tabs > li {
  margin-bottom: -1px;
}
.bootstrap .nav-tabs > li > a {
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 20px;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
}
.bootstrap .nav-tabs > li > a:hover {
  border-color: #eeeeee #eeeeee #dddddd;
}
.bootstrap .nav-tabs > .active > a,
.bootstrap .nav-tabs > .active > a:hover {
  color: #555555;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
  cursor: default;
}
.bootstrap .nav-pills > li > a {
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 2px;
  margin-bottom: 2px;
  border-radius: 5px;
}
.bootstrap .nav-pills > .active > a,
.bootstrap .nav-pills > .active > a:hover {
  color: #ffffff;
  background-color: #0088cc;
}
.bootstrap .nav-stacked > li {
  float: none;
}
.bootstrap .nav-stacked > li > a {
  margin-right: 0;
}
.bootstrap .nav-tabs.nav-stacked {
  border-bottom: 0;
}
.bootstrap .nav-tabs.nav-stacked > li > a {
  border: 1px solid #ddd;
  border-radius: 0;
}
.bootstrap .nav-tabs.nav-stacked > li:first-child > a {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.bootstrap .nav-tabs.nav-stacked > li:last-child > a {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.bootstrap .nav-tabs.nav-stacked > li > a:hover {
  border-color: #ddd;
  z-index: 2;
}
.bootstrap .nav-pills.nav-stacked > li > a {
  margin-bottom: 3px;
}
.bootstrap .nav-pills.nav-stacked > li:last-child > a {
  margin-bottom: 1px;
}
.bootstrap .nav-tabs .dropdown-menu {
  border-radius: 0 0 6px 6px;
}
.bootstrap .nav-pills .dropdown-menu {
  border-radius: 6px;
}
.bootstrap .nav .dropdown-toggle .caret {
  border-top-color: #0088cc;
  border-bottom-color: #0088cc;
  margin-top: 6px;
}
.bootstrap .nav .dropdown-toggle:hover .caret {
  border-top-color: #005580;
  border-bottom-color: #005580;
}
.bootstrap .nav-tabs .dropdown-toggle .caret {
  margin-top: 8px;
}
.bootstrap .nav .active .dropdown-toggle .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
}
.bootstrap .nav-tabs .active .dropdown-toggle .caret {
  border-top-color: #555555;
  border-bottom-color: #555555;
}
.bootstrap .nav > .dropdown.active > a:hover {
  cursor: pointer;
}
.bootstrap .nav-tabs .open .dropdown-toggle,
.bootstrap .nav-pills .open .dropdown-toggle,
.bootstrap .nav > li.dropdown.open.active > a:hover {
  color: #ffffff;
  background-color: #999999;
  border-color: #999999;
}
.bootstrap .nav li.dropdown.open .caret,
.bootstrap .nav li.dropdown.open.active .caret,
.bootstrap .nav li.dropdown.open a:hover .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}
.bootstrap .tabs-stacked .open > a:hover {
  border-color: #999999;
}
.bootstrap .tabbable:before,
.bootstrap .tabbable:after {
  display: table;
  content: "";
  line-height: 0;
}
.bootstrap .tabbable:after {
  clear: both;
}
.bootstrap .tab-content {
  overflow: auto;
}
.bootstrap .tabs-below > .nav-tabs,
.bootstrap .tabs-right > .nav-tabs,
.bootstrap .tabs-left > .nav-tabs {
  border-bottom: 0;
}
.bootstrap .tab-content > .tab-pane,
.bootstrap .pill-content > .pill-pane {
  display: none;
}
.bootstrap .tab-content > .active,
.bootstrap .pill-content > .active {
  display: block;
}
.bootstrap .tabs-below > .nav-tabs {
  border-top: 1px solid #ddd;
}
.bootstrap .tabs-below > .nav-tabs > li {
  margin-top: -1px;
  margin-bottom: 0;
}
.bootstrap .tabs-below > .nav-tabs > li > a {
  border-radius: 0 0 4px 4px;
}
.bootstrap .tabs-below > .nav-tabs > li > a:hover {
  border-bottom-color: transparent;
  border-top-color: #ddd;
}
.bootstrap .tabs-below > .nav-tabs > .active > a,
.bootstrap .tabs-below > .nav-tabs > .active > a:hover {
  border-color: transparent #ddd #ddd #ddd;
}
.bootstrap .tabs-left > .nav-tabs > li,
.bootstrap .tabs-right > .nav-tabs > li {
  float: none;
}
.bootstrap .tabs-left > .nav-tabs > li > a,
.bootstrap .tabs-right > .nav-tabs > li > a {
  min-width: 74px;
  margin-right: 0;
  margin-bottom: 3px;
}
.bootstrap .tabs-left > .nav-tabs {
  float: left;
  margin-right: 19px;
  border-right: 1px solid #ddd;
}
.bootstrap .tabs-left > .nav-tabs > li > a {
  margin-right: -1px;
  border-radius: 4px 0 0 4px;
}
.bootstrap .tabs-left > .nav-tabs > li > a:hover {
  border-color: #eeeeee #dddddd #eeeeee #eeeeee;
}
.bootstrap .tabs-left > .nav-tabs .active > a,
.bootstrap .tabs-left > .nav-tabs .active > a:hover {
  border-color: #ddd transparent #ddd #ddd;
}
.bootstrap .tabs-right > .nav-tabs {
  float: right;
  margin-left: 19px;
  border-left: 1px solid #ddd;
}
.bootstrap .tabs-right > .nav-tabs > li > a {
  margin-left: -1px;
  border-radius: 0 4px 4px 0;
}
.bootstrap .tabs-right > .nav-tabs > li > a:hover {
  border-color: #eeeeee #eeeeee #eeeeee #dddddd;
}
.bootstrap .tabs-right > .nav-tabs .active > a,
.bootstrap .tabs-right > .nav-tabs .active > a:hover {
  border-color: #ddd #ddd #ddd transparent;
}
.bootstrap .nav > .disabled > a {
  color: #999999;
}
.bootstrap .nav > .disabled > a:hover {
  text-decoration: none;
  background-color: transparent;
  cursor: default;
}
.bootstrap .navbar {
  overflow: visible;
  margin-bottom: 20px;
  color: #777777;
}
.bootstrap .navbar-inner {
  min-height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #fafafa;
  background-image: linear-gradient(to bottom, #ffffff, #f2f2f2);
  background-repeat: repeat-x;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
}
.bootstrap .navbar-inner:before,
.bootstrap .navbar-inner:after {
  display: table;
  content: "";
  line-height: 0;
}
.bootstrap .navbar-inner:after {
  clear: both;
}
.bootstrap .navbar .container {
  width: auto;
}
.bootstrap .nav-collapse.collapse {
  height: auto;
  overflow: visible;
}
.bootstrap .navbar .brand {
  float: left;
  display: block;
  padding: 10px 20px 10px;
  margin-left: -20px;
  font-size: 20px;
  font-weight: 200;
  color: #777777;
  text-shadow: 0 1px 0 #ffffff;
}
.bootstrap .navbar .brand:hover {
  text-decoration: none;
}
.bootstrap .navbar-text {
  margin-bottom: 0;
  line-height: 40px;
}
.bootstrap .navbar-link {
  color: #777777;
}
.bootstrap .navbar-link:hover {
  color: #333333;
}
.bootstrap .navbar .divider-vertical {
  height: 40px;
  margin: 0 9px;
  border-left: 1px solid #f2f2f2;
  border-right: 1px solid #ffffff;
}
.bootstrap .navbar .btn,
.bootstrap .navbar .btn-group {
  margin-top: 5px;
}
.bootstrap .navbar .btn-group .btn,
.bootstrap .navbar .input-prepend .btn,
.bootstrap .navbar .input-append .btn {
  margin-top: 0;
}
.bootstrap .navbar-form {
  margin-bottom: 0;
}
.bootstrap .navbar-form:before,
.bootstrap .navbar-form:after {
  display: table;
  content: "";
  line-height: 0;
}
.bootstrap .navbar-form:after {
  clear: both;
}
.bootstrap .navbar-form input,
.bootstrap .navbar-form select,
.bootstrap .navbar-form .radio,
.bootstrap .navbar-form .checkbox {
  margin-top: 5px;
}
.bootstrap .navbar-form input,
.bootstrap .navbar-form select,
.bootstrap .navbar-form .btn {
  display: inline-block;
  margin-bottom: 0;
}
.bootstrap .navbar-form input[type="image"],
.bootstrap .navbar-form input[type="checkbox"],
.bootstrap .navbar-form input[type="radio"] {
  margin-top: 3px;
}
.bootstrap .navbar-form .input-append,
.bootstrap .navbar-form .input-prepend {
  margin-top: 6px;
  white-space: nowrap;
}
.bootstrap .navbar-form .input-append input,
.bootstrap .navbar-form .input-prepend input {
  margin-top: 0;
}
.bootstrap .navbar-search {
  position: relative;
  float: left;
  margin-top: 5px;
  margin-bottom: 0;
}
.bootstrap .navbar-search .search-query {
  margin-bottom: 0;
  padding: 4px 14px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: normal;
  line-height: 1;
  border-radius: 15px;
}
.bootstrap .navbar-static-top {
  position: static;
  margin-bottom: 0;
}
.bootstrap .navbar-static-top .navbar-inner {
  border-radius: 0;
}
.bootstrap .navbar-fixed-top,
.bootstrap .navbar-fixed-bottom {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  margin-bottom: 0;
}
.bootstrap .navbar-fixed-top .navbar-inner,
.bootstrap .navbar-static-top .navbar-inner {
  border-width: 0 0 1px;
}
.bootstrap .navbar-fixed-bottom .navbar-inner {
  border-width: 1px 0 0;
}
.bootstrap .navbar-fixed-top .navbar-inner,
.bootstrap .navbar-fixed-bottom .navbar-inner {
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
}
.bootstrap .navbar-static-top .container,
.bootstrap .navbar-fixed-top .container,
.bootstrap .navbar-fixed-bottom .container {
  width: 940px;
}
.bootstrap .navbar-fixed-top {
  top: 0;
}
.bootstrap .navbar-fixed-top .navbar-inner,
.bootstrap .navbar-static-top .navbar-inner {
  box-shadow: 0 1px 10px rgba(0,0,0,.1);
}
.bootstrap .navbar-fixed-bottom {
  bottom: 0;
}
.bootstrap .navbar-fixed-bottom .navbar-inner {
  box-shadow: 0 -1px 10px rgba(0,0,0,.1);
}
.bootstrap .navbar .nav {
  position: relative;
  left: 0;
  display: block;
  float: left;
  margin: 0 10px 0 0;
}
.bootstrap .navbar .nav.pull-right {
  float: right;
  margin-right: 0;
}
.bootstrap .navbar .nav > li {
  float: left;
}
.bootstrap .navbar .nav > li > a {
  float: none;
  padding: 10px 15px 10px;
  color: #777777;
  text-decoration: none;
  text-shadow: 0 1px 0 #ffffff;
}
.bootstrap .navbar .nav .dropdown-toggle .caret {
  margin-top: 8px;
}
.bootstrap .navbar .nav > li > a:focus,
.bootstrap .navbar .nav > li > a:hover {
  background-color: transparent;
  color: #333333;
  text-decoration: none;
}
.bootstrap .navbar .nav > .active > a,
.bootstrap .navbar .nav > .active > a:hover,
.bootstrap .navbar .nav > .active > a:focus {
  color: #555555;
  text-decoration: none;
  background-color: #e5e5e5;
  box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.125);
}
.bootstrap .navbar .btn-navbar {
  display: none;
  float: right;
  padding: 7px 10px;
  margin-left: 5px;
  margin-right: 5px;
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #ededed;
  background-image: linear-gradient(to bottom, #f2f2f2, #e5e5e5);
  background-repeat: repeat-x;
  border-color: #e5e5e5 #e5e5e5 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.075);
}
.bootstrap .navbar .btn-navbar:hover,
.bootstrap .navbar .btn-navbar:active,
.bootstrap .navbar .btn-navbar.active,
.bootstrap .navbar .btn-navbar.disabled,
.bootstrap .navbar .btn-navbar[disabled] {
  color: #ffffff;
  background-color: #e5e5e5;
}
.bootstrap .navbar .btn-navbar .icon-bar {
  display: block;
  width: 18px;
  height: 2px;
  background-color: #f5f5f5;
  border-radius: 1px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
}
.bootstrap .btn-navbar .icon-bar + .icon-bar {
  margin-top: 3px;
}
.bootstrap .navbar .nav > li > .dropdown-menu:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -7px;
  left: 9px;
}
.bootstrap .navbar .nav > li > .dropdown-menu:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  position: absolute;
  top: -6px;
  left: 10px;
}
.bootstrap .navbar-fixed-bottom .nav > li > .dropdown-menu:before {
  border-top: 7px solid #ccc;
  border-top-color: rgba(0, 0, 0, 0.2);
  border-bottom: 0;
  bottom: -7px;
  top: auto;
}
.bootstrap .navbar-fixed-bottom .nav > li > .dropdown-menu:after {
  border-top: 6px solid #ffffff;
  border-bottom: 0;
  bottom: -6px;
  top: auto;
}
.bootstrap .navbar .nav li.dropdown.open > .dropdown-toggle,
.bootstrap .navbar .nav li.dropdown.active > .dropdown-toggle,
.bootstrap .navbar .nav li.dropdown.open.active > .dropdown-toggle {
  background-color: #e5e5e5;
  color: #555555;
}
.bootstrap .navbar .nav li.dropdown > .dropdown-toggle .caret {
  border-top-color: #777777;
  border-bottom-color: #777777;
}
.bootstrap .navbar .nav li.dropdown.open > .dropdown-toggle .caret,
.bootstrap .navbar .nav li.dropdown.active > .dropdown-toggle .caret,
.bootstrap .navbar .nav li.dropdown.open.active > .dropdown-toggle .caret {
  border-top-color: #555555;
  border-bottom-color: #555555;
}
.bootstrap .navbar .pull-right > li > .dropdown-menu,
.bootstrap .navbar .nav > li > .dropdown-menu.pull-right {
  left: auto;
  right: 0;
}
.bootstrap .navbar .pull-right > li > .dropdown-menu:before,
.bootstrap .navbar .nav > li > .dropdown-menu.pull-right:before {
  left: auto;
  right: 12px;
}
.bootstrap .navbar .pull-right > li > .dropdown-menu:after,
.bootstrap .navbar .nav > li > .dropdown-menu.pull-right:after {
  left: auto;
  right: 13px;
}
.bootstrap .navbar .pull-right > li > .dropdown-menu .dropdown-menu,
.bootstrap .navbar .nav > li > .dropdown-menu.pull-right .dropdown-menu {
  left: auto;
  right: 100%;
  margin-left: 0;
  margin-right: -1px;
  border-radius: 6px 0 6px 6px;
}
.bootstrap .navbar-inverse {
  color: #999999;
}
.bootstrap .navbar-inverse .navbar-inner {
  background-color: #1b1b1b;
  background-image: linear-gradient(to bottom, #222222, #111111);
  background-repeat: repeat-x;
  border-color: #252525;
}
.bootstrap .navbar-inverse .brand,
.bootstrap .navbar-inverse .nav > li > a {
  color: #999999;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.bootstrap .navbar-inverse .brand:hover,
.bootstrap .navbar-inverse .nav > li > a:hover {
  color: #ffffff;
}
.bootstrap .navbar-inverse .nav > li > a:focus,
.bootstrap .navbar-inverse .nav > li > a:hover {
  background-color: transparent;
  color: #ffffff;
}
.bootstrap .navbar-inverse .nav .active > a,
.bootstrap .navbar-inverse .nav .active > a:hover,
.bootstrap .navbar-inverse .nav .active > a:focus {
  color: #ffffff;
  background-color: #111111;
}
.bootstrap .navbar-inverse .navbar-link {
  color: #999999;
}
.bootstrap .navbar-inverse .navbar-link:hover {
  color: #ffffff;
}
.bootstrap .navbar-inverse .divider-vertical {
  border-left-color: #111111;
  border-right-color: #222222;
}
.bootstrap .navbar-inverse .nav li.dropdown.open > .dropdown-toggle,
.bootstrap .navbar-inverse .nav li.dropdown.active > .dropdown-toggle,
.bootstrap .navbar-inverse .nav li.dropdown.open.active > .dropdown-toggle {
  background-color: #111111;
  color: #ffffff;
}
.bootstrap .navbar-inverse .nav li.dropdown > .dropdown-toggle .caret {
  border-top-color: #999999;
  border-bottom-color: #999999;
}
.bootstrap .navbar-inverse .nav li.dropdown.open > .dropdown-toggle .caret,
.bootstrap .navbar-inverse .nav li.dropdown.active > .dropdown-toggle .caret,
.bootstrap .navbar-inverse .nav li.dropdown.open.active > .dropdown-toggle .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
}
.bootstrap .navbar-inverse .navbar-search .search-query {
  color: #ffffff;
  background-color: #515151;
  border-color: #111111;
  box-shadow: inset 0 1px 2px rgba(0,0,0,.1), 0 1px 0 rgba(255,255,255,.15);
  transition: none;
}
.bootstrap .navbar-inverse .navbar-search .search-query:focus,
.bootstrap .navbar-inverse .navbar-search .search-query.focused {
  padding: 5px 15px;
  color: #333333;
  text-shadow: 0 1px 0 #ffffff;
  background-color: #ffffff;
  border: 0;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  outline: 0;
}
.bootstrap .navbar-inverse .btn-navbar {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #0e0e0e;
  background-image: linear-gradient(to bottom, #151515, #040404);
  background-repeat: repeat-x;
  border-color: #040404 #040404 #000000;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);

}
.bootstrap .navbar-inverse .btn-navbar:hover,
.bootstrap .navbar-inverse .btn-navbar:active,
.bootstrap .navbar-inverse .btn-navbar.active,
.bootstrap .navbar-inverse .btn-navbar.disabled,
.bootstrap .navbar-inverse .btn-navbar[disabled] {
  color: #ffffff;
  background-color: #040404;
}
.bootstrap .breadcrumb {
  padding: 8px 15px;
  margin: 0 0 20px;
  list-style: none;
  background-color: #f5f5f5;
  border-radius: 4px;
}
.bootstrap .breadcrumb li {
  display: inline-block;
  text-shadow: 0 1px 0 #ffffff;
}
.bootstrap .breadcrumb .divider {
  padding: 0 5px;
  color: #ccc;
}
.bootstrap .breadcrumb .active {
  color: #999999;
}
.bootstrap .pagination {
  margin: 20px 0;
}
.bootstrap .pagination ul {
  display: inline-block;
  margin-left: 0;
  margin-bottom: 0;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
.bootstrap .pagination ul > li {
  display: inline;
}
.bootstrap .pagination ul > li > a,
.bootstrap .pagination ul > li > span {
  float: left;
  padding: 4px 12px;
  line-height: 20px;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-left-width: 0;
}
.bootstrap .pagination ul > li > a:hover,
.bootstrap .pagination ul > .active > a,
.bootstrap .pagination ul > .active > span {
  background-color: #f5f5f5;
}
.bootstrap .pagination ul > .active > a,
.bootstrap .pagination ul > .active > span {
  color: #999999;
  cursor: default;
}
.bootstrap .pagination ul > .disabled > span,
.bootstrap .pagination ul > .disabled > a,
.bootstrap .pagination ul > .disabled > a:hover {
  color: #999999;
  background-color: transparent;
  cursor: default;
}
.bootstrap .pagination ul > li:first-child > a,
.bootstrap .pagination ul > li:first-child > span {
  border-left-width: 1px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.bootstrap .pagination ul > li:last-child > a,
.bootstrap .pagination ul > li:last-child > span {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.bootstrap .pagination-centered {
  text-align: center;
}
.bootstrap .pagination-right {
  text-align: right;
}
.bootstrap .pagination-large ul > li > a,
.bootstrap .pagination-large ul > li > span {
  padding: 11px 19px;
  font-size: 17.5px;
}
.bootstrap .pagination-large ul > li:first-child > a,
.bootstrap .pagination-large ul > li:first-child > span {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.bootstrap .pagination-large ul > li:last-child > a,
.bootstrap .pagination-large ul > li:last-child > span {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.bootstrap .pagination-mini ul > li:first-child > a,
.bootstrap .pagination-small ul > li:first-child > a,
.bootstrap .pagination-mini ul > li:first-child > span,
.bootstrap .pagination-small ul > li:first-child > span {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.bootstrap .pagination-mini ul > li:last-child > a,
.bootstrap .pagination-small ul > li:last-child > a,
.bootstrap .pagination-mini ul > li:last-child > span,
.bootstrap .pagination-small ul > li:last-child > span {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.bootstrap .pagination-small ul > li > a,
.bootstrap .pagination-small ul > li > span {
  padding: 2px 10px;
  font-size: 11.9px;
}
.bootstrap .pagination-mini ul > li > a,
.bootstrap .pagination-mini ul > li > span {
  padding: 1px 6px;
  font-size: 10.5px;
}
.bootstrap .pager {
  margin: 20px 0;
  list-style: none;
  text-align: center;
}
.bootstrap .pager:before,
.bootstrap .pager:after {
  display: table;
  content: "";
  line-height: 0;
}
.bootstrap .pager:after {
  clear: both;
}
.bootstrap .pager li {
  display: inline;
}
.bootstrap .pager li > a,
.bootstrap .pager li > span {
  display: inline-block;
  padding: 5px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
}
.bootstrap .pager li > a:hover {
  text-decoration: none;
  background-color: #f5f5f5;
}
.bootstrap .pager .next > a,
.bootstrap .pager .next > span {
  float: right;
}
.bootstrap .pager .previous > a,
.bootstrap .pager .previous > span {
  float: left;
}
.bootstrap .pager .disabled > a,
.bootstrap .pager .disabled > a:hover,
.bootstrap .pager .disabled > span {
  color: #999999;
  background-color: #fff;
  cursor: default;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop,
.modal-backdrop.fade.in {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.modal {
  position: fixed;
  top: 10%;
  left: 50%;
  z-index: 1050;
  width: 560px;
  margin: -250px 0 0 -280px;
  background-color: #ffffff;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.3);

  border-radius: 6px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  background-clip: padding-box;
  outline: none;
}

@media screen and (max-width:580px) {
   .modal {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      margin: 0;
   }
}

.modal.fade {
  transition: opacity .3s linear, top .3s ease-out;
  top: -25%;
}
.modal.fade.in {
  top: 50%;
}
.modal-header {
  padding: 9px 15px;
  border-bottom: 1px solid #eee;
}
.modal-header .close {
  margin-top: 2px;
}
.modal-header h3 {
  margin: 0;
  line-height: 30px;
}
.modal-body {
  position: relative;
  overflow-y: auto;
  max-height: 400px;
  padding: 15px;
}
.modal-form {
  margin-bottom: 0;
}
.modal-footer {
  padding: 14px 15px 15px;
  margin-bottom: 0;
  text-align: right;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  border-radius: 0 0 6px 6px;
  box-shadow: inset 0 1px 0 #ffffff;
}
.modal-footer:before,
.modal-footer:after {
  display: table;
  content: "";
  line-height: 0;
}
.modal-footer:after {
  clear: both;
}
.modal-footer .btn + .btn {
  margin-left: 5px;
  margin-bottom: 0;
}
.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}
.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}
.tooltip {
  position: absolute;
  z-index: 1030;
  display: block;
  visibility: visible;
  padding: 5px;
  font-size: 11px;
  opacity: 0;
  filter: alpha(opacity=0);
}
.tooltip.in {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.tooltip.top {
  margin-top: -3px;
}
.tooltip.right {
  margin-left: 3px;
}
.tooltip.bottom {
  margin-top: 3px;
}
.tooltip.left {
  margin-left: -3px;
}
.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  background-color: #000000;
  border-radius: 4px;
}
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000000;
}
.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000000;
}
.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000000;
}
.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000000;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1010;
  display: none;
  width: 236px;
  padding: 1px;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.popover.top {
  margin-top: -10px;
}
.popover.right {
  margin-left: 10px;
}
.popover.bottom {
  margin-top: 10px;
}
.popover.left {
  margin-left: -10px;
}
.popover-title {
  margin: 0;
  padding: 8px 14px;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
}
.popover-content {
  padding: 9px 14px;
}
.popover-content p,
.popover-content ul,
.popover-content ol {
  margin-bottom: 0;
}
.popover .arrow,
.popover .arrow:after {
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.popover .arrow:after {
  content: "";
  z-index: -1;
}
.popover.top .arrow {
  bottom: -10px;
  left: 50%;
  margin-left: -10px;
  border-width: 10px 10px 0;
  border-top-color: #ffffff;
}
.popover.top .arrow:after {
  border-width: 11px 11px 0;
  border-top-color: rgba(0, 0, 0, 0.25);
  bottom: -1px;
  left: -11px;
}
.popover.right .arrow {
  top: 50%;
  left: -10px;
  margin-top: -10px;
  border-width: 10px 10px 10px 0;
  border-right-color: #ffffff;
}
.popover.right .arrow:after {
  border-width: 11px 11px 11px 0;
  border-right-color: rgba(0, 0, 0, 0.25);
  bottom: -11px;
  left: -1px;
}
.popover.bottom .arrow {
  top: -10px;
  left: 50%;
  margin-left: -10px;
  border-width: 0 10px 10px;
  border-bottom-color: #ffffff;
}
.popover.bottom .arrow:after {
  border-width: 0 11px 11px;
  border-bottom-color: rgba(0, 0, 0, 0.25);
  top: -1px;
  left: -11px;
}
.popover.left .arrow {
  top: 50%;
  right: -10px;
  margin-top: -10px;
  border-width: 10px 0 10px 10px;
  border-left-color: #ffffff;
}
.popover.left .arrow:after {
  border-width: 11px 0 11px 11px;
  border-left-color: rgba(0, 0, 0, 0.25);
  bottom: -11px;
  right: -1px;
}
.bootstrap .thumbnails {
  margin-left: -20px;
  list-style: none;
}
.bootstrap .thumbnails:before,
.bootstrap .thumbnails:after {
  display: table;
  content: "";
  line-height: 0;
}
.bootstrap .thumbnails:after {
  clear: both;
}
.bootstrap .row-fluid .thumbnails {
  margin-left: 0;
}
.bootstrap .thumbnails > li {
  float: left;
  margin-bottom: 20px;
  margin-left: 20px;
}
.bootstrap .thumbnail {
  display: block;
  padding: 4px;
  line-height: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
  transition: all 0.2s ease-in-out;
}
.bootstrap a.thumbnail:hover {
  border-color: #0088cc;
  box-shadow: 0 1px 4px rgba(0, 105, 214, 0.25);
}
.bootstrap .thumbnail > img {
  display: block;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.bootstrap .thumbnail .caption {
  padding: 9px;
  color: #555555;
}
.bootstrap .media,
.bootstrap .media-body {
  overflow: hidden;
}
.bootstrap .media,
.bootstrap .media .media {
  margin-top: 15px;
}
.bootstrap .media:first-child {
  margin-top: 0;
}
.bootstrap .media-object {
  display: block;
}
.bootstrap .media-heading {
  margin: 0 0 5px;
}
.bootstrap .media .pull-left {
  margin-right: 10px;
}
.bootstrap .media .pull-right {
  margin-left: 10px;
}
.bootstrap .media-list {
  margin-left: 0;
  list-style: none;
}
.bootstrap .label,
.bootstrap .badge {
  display: inline-block;
  padding: 2px 4px;
  font-size: 11.844px;
  font-weight: bold;
  line-height: 14px;
  color: #ffffff;
  vertical-align: baseline;
  white-space: nowrap;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #999999;
}
.bootstrap .label {
  border-radius: 3px;
}
.bootstrap .badge {
  padding-left: 9px;
  padding-right: 9px;
  border-radius: 9px;
}
.bootstrap a.label:hover,
.bootstrap a.badge:hover {
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}
.bootstrap .label-important,
.bootstrap .badge-important {
  background-color: #b94a48;
}
.bootstrap .label-important[href],
.bootstrap .badge-important[href] {
  background-color: #953b39;
}
.bootstrap .label-warning,
.bootstrap .badge-warning {
  background-color: #f89406;
}
.bootstrap .label-warning[href],
.bootstrap .badge-warning[href] {
  background-color: #c67605;
}
.bootstrap .label-success,
.bootstrap .badge-success {
  background-color: #468847;
}
.bootstrap .label-success[href],
.bootstrap .badge-success[href] {
  background-color: #356635;
}
.bootstrap .label-info,
.bootstrap .badge-info {
  background-color: #3a87ad;
}
.bootstrap .label-info[href],
.bootstrap .badge-info[href] {
  background-color: #2d6987;
}
.bootstrap .label-inverse,
.bootstrap .badge-inverse {
  background-color: #333333;
}
.bootstrap .label-inverse[href],
.bootstrap .badge-inverse[href] {
  background-color: #1a1a1a;
}
.bootstrap .btn .label,
.bootstrap .btn .badge {
  position: relative;
  top: -1px;
}
.bootstrap .btn-mini .label,
.bootstrap .btn-mini .badge {
  top: 0;
}
@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
.bootstrap .progress {
  overflow: hidden;
  height: 20px;
  margin-bottom: 20px;
  background-color: #f7f7f7;
  background-image: linear-gradient(to bottom, #f5f5f5, #f9f9f9);
  background-repeat: repeat-x;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.bootstrap .progress .bar {
  width: 0%;
  height: 100%;
  color: #ffffff;
  float: left;
  font-size: 12px;
  text-align: center;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #0e90d2;
  background-image: linear-gradient(to bottom, #149bdf, #0480be);
  background-repeat: repeat-x;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  transition: width 0.6s ease;
}
.bootstrap .progress .bar + .bar {
  box-shadow: inset 1px 0 0 rgba(0,0,0,.15), inset 0 -1px 0 rgba(0,0,0,.15);
}
.bootstrap .progress-striped .bar {
  background-color: #149bdf;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 40px 40px;
}
.bootstrap .progress.active .bar {
  animation: progress-bar-stripes 2s linear infinite;
}
.bootstrap .progress-danger .bar,
.bootstrap .progress .bar-danger {
  background-color: #dd514c;
  background-image: linear-gradient(to bottom, #ee5f5b, #c43c35);
  background-repeat: repeat-x;
}
.bootstrap .progress-danger.progress-striped .bar,
.bootstrap .progress-striped .bar-danger {
  background-color: #ee5f5b;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.bootstrap .progress-success .bar,
.bootstrap .progress .bar-success {
  background-color: #5eb95e;
  background-image: linear-gradient(to bottom, #62c462, #57a957);
  background-repeat: repeat-x;
}
.bootstrap .progress-success.progress-striped .bar,
.bootstrap .progress-striped .bar-success {
  background-color: #62c462;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.bootstrap .progress-info .bar,
.bootstrap .progress .bar-info {
  background-color: #4bb1cf;
  background-image: linear-gradient(to bottom, #5bc0de, #339bb9);
  background-repeat: repeat-x;
}
.bootstrap .progress-info.progress-striped .bar,
.bootstrap .progress-striped .bar-info {
  background-color: #5bc0de;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.bootstrap .progress-warning .bar,
.bootstrap .progress .bar-warning {
  background-color: #faa732;
  background-image: linear-gradient(to bottom, #fbb450, #f89406);
  background-repeat: repeat-x;
}
.bootstrap .progress-warning.progress-striped .bar,
.bootstrap .progress-striped .bar-warning {
  background-color: #fbb450;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.bootstrap .accordion {
  margin-bottom: 20px;
}
.bootstrap .accordion-group {
  margin-bottom: 2px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}
.bootstrap .accordion-heading {
  border-bottom: 0;
}
.bootstrap .accordion-heading .accordion-toggle {
  display: block;
  padding: 8px 15px;
}
.bootstrap .accordion-toggle {
  cursor: pointer;
}
.bootstrap .accordion-inner {
  padding: 9px 15px;
  border-top: 1px solid #e5e5e5;
}
.bootstrap .carousel {
  position: relative;
  margin-bottom: 20px;
  line-height: 1;
}
.bootstrap .carousel-inner {
  overflow: hidden;
  width: 100%;
  position: relative;
}
.bootstrap .carousel .item {
  display: none;
  position: relative;
  transition: 0.3s ease-in-out left;
}
.bootstrap .carousel .item > img {
  display: block;
  line-height: 1;
}
.bootstrap .carousel .active,
.bootstrap .carousel .next,
.bootstrap .carousel .prev {
  display: block;
}
.bootstrap .carousel .active {
  left: 0;
}
.bootstrap .carousel .next,
.bootstrap .carousel .prev {
  position: absolute;
  top: 0;
  width: 100%;
}
.bootstrap .carousel .next {
  left: 100%;
}
.bootstrap .carousel .prev {
  left: -100%;
}
.bootstrap .carousel .next.left,
.bootstrap .carousel .prev.right {
  left: 0;
}
.bootstrap .carousel .active.left {
  left: -100%;
}
.bootstrap .carousel .active.right {
  left: 100%;
}
.bootstrap .carousel-control {
  position: absolute;
  top: 40%;
  left: 15px;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  font-size: 60px;
  font-weight: 100;
  line-height: 30px;
  color: #ffffff;
  text-align: center;
  background: #222222;
  border: 3px solid #ffffff;
  border-radius: 23px;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.bootstrap .carousel-control.right {
  left: auto;
  right: 15px;
}
.bootstrap .carousel-control:hover {
  color: #ffffff;
  text-decoration: none;
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.bootstrap .carousel-caption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px;
  background: #333333;
  background: rgba(0, 0, 0, 0.75);
}
.bootstrap .carousel-caption h4,
.bootstrap .carousel-caption p {
  color: #ffffff;
  line-height: 20px;
}
.bootstrap .carousel-caption h4 {
  margin: 0 0 5px;
}
.bootstrap .carousel-caption p {
  margin-bottom: 0;
}
.bootstrap .hero-unit {
  padding: 60px;
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 200;
  line-height: 30px;
  color: inherit;
  background-color: #eeeeee;
  border-radius: 6px;
}
.bootstrap .hero-unit h1 {
  margin-bottom: 0;
  font-size: 60px;
  line-height: 1;
  color: inherit;
  letter-spacing: -1px;
}
.bootstrap .hero-unit li {
  line-height: 30px;
}
.bootstrap .pull-right {
  float: right;
}
.bootstrap .pull-left {
  float: left;
}
.bootstrap .hide {
  display: none;
}
.bootstrap .show {
  display: block;
}
.bootstrap .invisible {
  visibility: hidden;
}
.bootstrap .affix {
  position: fixed;
}

.bootstrap table {
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
}
.bootstrap .table {
  width: 100%;
  margin-bottom: 20px;
}
.bootstrap .table th,
.bootstrap .table td {
  padding: 8px;
  line-height: 20px;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #dddddd;
}
.bootstrap .table th {
  font-weight: bold;
}
.bootstrap .table thead th {
  vertical-align: bottom;
}
.bootstrap .table caption + thead tr:first-child th,
.bootstrap .table caption + thead tr:first-child td,
.bootstrap .table colgroup + thead tr:first-child th,
.bootstrap .table colgroup + thead tr:first-child td,
.bootstrap .table thead:first-child tr:first-child th,
.bootstrap .table thead:first-child tr:first-child td {
  border-top: 0;
}
.bootstrap .table tbody + tbody {
  border-top: 2px solid #dddddd;
}
.bootstrap .table .table {
  background-color: #ffffff;
}
.bootstrap .table-condensed th,
.bootstrap .table-condensed td {
  padding: 4px 5px;
}
.bootstrap .table-bordered {
  border: 1px solid #dddddd;
  border-collapse: separate;
  border-left: 0;
  border-radius: 4px;
}
.bootstrap .table-bordered th,
.bootstrap .table-bordered td {
  border-left: 1px solid #dddddd;
}
.bootstrap .table-bordered caption + thead tr:first-child th,
.bootstrap .table-bordered caption + tbody tr:first-child th,
.bootstrap .table-bordered caption + tbody tr:first-child td,
.bootstrap .table-bordered colgroup + thead tr:first-child th,
.bootstrap .table-bordered colgroup + tbody tr:first-child th,
.bootstrap .table-bordered colgroup + tbody tr:first-child td,
.bootstrap .table-bordered thead:first-child tr:first-child th,
.bootstrap .table-bordered tbody:first-child tr:first-child th,
.bootstrap .table-bordered tbody:first-child tr:first-child td {
  border-top: 0;
}
.bootstrap .table-bordered thead:first-child tr:first-child > th:first-child,
.bootstrap .table-bordered tbody:first-child tr:first-child > td:first-child {
  border-top-left-radius: 4px;
}
.bootstrap .table-bordered thead:first-child tr:first-child > th:last-child,
.bootstrap .table-bordered tbody:first-child tr:first-child > td:last-child {
  border-top-right-radius: 4px;
}
.bootstrap .table-bordered thead:last-child tr:last-child > th:first-child,
.bootstrap .table-bordered tbody:last-child tr:last-child > td:first-child,
.bootstrap .table-bordered tfoot:last-child tr:last-child > td:first-child {
  border-bottom-left-radius: 4px;
}
.bootstrap .table-bordered thead:last-child tr:last-child > th:last-child,
.bootstrap .table-bordered tbody:last-child tr:last-child > td:last-child,
.bootstrap .table-bordered tfoot:last-child tr:last-child > td:last-child {
  border-bottom-right-radius: 4px;
}
.bootstrap .table-bordered tfoot + tbody:last-child tr:last-child td:first-child {
  border-bottom-left-radius: 0;
}
.bootstrap .table-bordered tfoot + tbody:last-child tr:last-child td:last-child {
  border-bottom-right-radius: 0;
}
.bootstrap .table-bordered caption + thead tr:first-child th:first-child,
.bootstrap .table-bordered caption + tbody tr:first-child td:first-child,
.bootstrap .table-bordered colgroup + thead tr:first-child th:first-child,
.bootstrap .table-bordered colgroup + tbody tr:first-child td:first-child {
  border-top-left-radius: 4px;
}
.bootstrap .table-bordered caption + thead tr:first-child th:last-child,
.bootstrap .table-bordered caption + tbody tr:first-child td:last-child,
.bootstrap .table-bordered colgroup + thead tr:first-child th:last-child,
.bootstrap .table-bordered colgroup + tbody tr:first-child td:last-child {
  border-top-right-radius: 4px;
}
.bootstrap .table-striped tbody > tr:nth-child(odd) > td,
.bootstrap .table-striped tbody > tr:nth-child(odd) > th {
  background-color: #f9f9f9;
}
.bootstrap .table-hover tbody tr:hover td,
.bootstrap .table-hover tbody tr:hover th {
  background-color: #f5f5f5;
}
.bootstrap table td[class*="span"],
.bootstrap table th[class*="span"],
.bootstrap .row-fluid table td[class*="span"],
.bootstrap .row-fluid table th[class*="span"] {
  display: table-cell;
  float: none;
  margin-left: 0;
}
.bootstrap .table td.span1,
.bootstrap .table th.span1 {
  float: none;
  width: 44px;
  margin-left: 0;
}
.bootstrap .table td.span2,
.bootstrap .table th.span2 {
  float: none;
  width: 124px;
  margin-left: 0;
}
.bootstrap .table td.span3,
.bootstrap .table th.span3 {
  float: none;
  width: 204px;
  margin-left: 0;
}
.bootstrap .table td.span4,
.bootstrap .table th.span4 {
  float: none;
  width: 284px;
  margin-left: 0;
}
.bootstrap .table td.span5,
.bootstrap .table th.span5 {
  float: none;
  width: 364px;
  margin-left: 0;
}
.bootstrap .table td.span6,
.bootstrap .table th.span6 {
  float: none;
  width: 444px;
  margin-left: 0;
}
.bootstrap .table td.span7,
.bootstrap .table th.span7 {
  float: none;
  width: 524px;
  margin-left: 0;
}
.bootstrap .table td.span8,
.bootstrap .table th.span8 {
  float: none;
  width: 604px;
  margin-left: 0;
}
.bootstrap .table td.span9,
.bootstrap .table th.span9 {
  float: none;
  width: 684px;
  margin-left: 0;
}
.bootstrap .table td.span10,
.bootstrap .table th.span10 {
  float: none;
  width: 764px;
  margin-left: 0;
}
.bootstrap .table td.span11,
.bootstrap .table th.span11 {
  float: none;
  width: 844px;
  margin-left: 0;
}
.bootstrap .table td.span12,
.bootstrap .table th.span12 {
  float: none;
  width: 924px;
  margin-left: 0;
}
.bootstrap .table tbody tr.success td {
  background-color: #dff0d8;
}
.bootstrap .table tbody tr.error td {
  background-color: #f2dede;
}
.bootstrap .table tbody tr.warning td {
  background-color: #fcf8e3;
}
.bootstrap .table tbody tr.info td {
  background-color: #d9edf7;
}
.bootstrap .table-hover tbody tr.success:hover td {
  background-color: #d0e9c6;
}
.bootstrap .table-hover tbody tr.error:hover td {
  background-color: #ebcccc;
}
.bootstrap .table-hover tbody tr.warning:hover td {
  background-color: #faf2cc;
}
.bootstrap .table-hover tbody tr.info:hover td {
  background-color: #c4e3f3;
}

.bootstrap dl {
  margin-bottom: 20px;
}
.bootstrap dt,
.bootstrap dd {
  line-height: 20px;
}
.bootstrap dt {
  font-weight: bold;
}
.bootstrap dd {
  margin-left: 10px;
}
.bootstrap .dl-horizontal:before,
.bootstrap .dl-horizontal:after {
  display: table;
  content: "";
  line-height: 0;
}
.bootstrap .dl-horizontal:after {
  clear: both;
}
.bootstrap .dl-horizontal dt {
  float: left;
  width: 160px;
  clear: left;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.bootstrap .dl-horizontal dd {
  margin-left: 180px;
}
/**
 * Copyright (C) SiteVision AB 2002-2014, all rights reserved
 *
 * @see CharResourceAction
 *
 * @author Karl Eklöf
 */

.sv-messages-portlet .sv-chat-new:before {
   content:"";
   display:inline-block;
   width:10px;
   height:10px;
   /*margin-right:.5em;*/
   background-color:#AAA;
   border-radius:10px
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}
.sv-messages-portlet .sv-conversation {
   vertical-align:top;
}

.sv-messages-portlet .sv-conversation-text {
   vertical-align:top;
}

.sv-messages-portlet .sv-conversations-notifier {
   vertical-align:top;
   font-weight:bold;
}

.sv-messages-portlet .sv-conversations-show-more {
   cursor:pointer;
   text-align:center
}

.sv-conversation-list li {
   font-size: 11px;
   padding: 3px 10px;
}

.sv-messages-portlet .sv-conversations-show-more {
   font-size: 11px;
   padding: .25em 0;
}


.sv-conversation-list a {
   text-decoration:none
}

.sv-messages-portlet .sv-show-scroll {
   overflow:auto;
   position:relative
}

.sv-conversation-list li .sv-buddy-icon {
   float:left
}

.sv-messages-portlet .popover-content {
   font-size: 11px;
   padding: 5px 0 5px 0
}


.sv-conversation-list li div.sv-conversation-text span.badge {
   font-size: 10px;
   padding: 2px 6px;
   line-height: 1;
   text-shadow: none;
}

.sv-conversation-list li div.sv-conversation-text p {
    margin: 0;
}

.sv-conversation-list li:hover div.sv-conversation-text p,
.sv-conversation-list li:focus div.sv-conversation-text p,
.sv-conversation-list li:active div.sv-conversation-text p,
.sv-conversation-list li:hover div.sv-conversation-text span,
.sv-conversation-list li:focus div.sv-conversation-text span,
.sv-conversation-list li:active div.sv-conversation-text span {
   color: white !important;
}

.sv-conversation-list li div.sv-conversation-text {
   overflow:hidden;
   padding-left:5px;
   white-space:normal
}

.sv-messages-portlet .sv-conversations-show-more:active,
.sv-messages-portlet .sv-conversations-show-more:focus,
.sv-messages-portlet .sv-conversations-show-more:hover,
.sv-conversation-list li:active,
.sv-conversation-list li:focus,
.sv-conversation-list li:hover {
   text-decoration: none;
   color: white !important;
   background-color: #0081c2;
   background-image: linear-gradient(to bottom, #0088cc, #0077b3);
}

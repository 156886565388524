/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author albin
 * Styles that are shared between all timeline portlets
 */

.sv-timeline-portlet .sv-buddy-icon,
.sv-timelinesearch-portlet .sv-buddy-icon,
.sv-tagtimeline-portlet .sv-buddy-icon,
.sv-timelineentry-portlet .sv-buddy-icon {
  width: 100%;
  border: none;
  max-width: 48px;
  max-height: 48px;
  margin-right: 1em;
}

.sv-timeline-portlet .sv-smaller.sv-buddy-icon,
.sv-timelinesearch-portlet .sv-smaller.sv-buddy-icon,
.sv-tagtimeline-portlet .sv-smaller.sv-buddy-icon,
.sv-timelineentry-portlet .sv-smaller.sv-buddy-icon {
  max-width: 36px;
  max-height: 36px;
}

.sv-timeline-portlet .sv-image-container,
.sv-timelinesearch-portlet .sv-image-container,
.sv-tagtimeline-portlet .sv-image-container,
.sv-timelineentry-portlet .sv-image-container {
  float: left;
  width: 10%;
}

.sv-timeline-portlet .sv-meta-info,
.sv-timelinesearch-portlet .sv-meta-info,
.sv-timelineentry-portlet .sv-meta-info,
.sv-tagtimeline-portlet .sv-meta-info {
  list-style: none;
  padding: 0;
  margin-top: 1em;
  box-sizing: border-box;
}

.sv-timeline-portlet .sv-timeline-comment .sv-meta-info,
.sv-timelinesearch-portlet .sv-timeline-comment .sv-meta-info,
.sv-timelineentry-portlet .sv-timeline-comment .sv-meta-info,
.sv-tagtimeline-portlet .sv-timeline-comment .sv-meta-info {
  margin: 0.5em 0;
}

.sv-timeline-portlet .sv-meta-info a,
.sv-timelinesearch-portlet .sv-meta-info a,
.sv-tagtimeline-portlet .sv-meta-info a,
.sv-timelineentry-portlet .sv-meta-info a {
  cursor: pointer;
}

.sv-timeline-portlet .sv-meta-info li,
.sv-timelinesearch-portlet .sv-meta-info li,
.sv-tagtimeline-portlet .sv-meta-info li,
.sv-timelineentry-portlet .sv-meta-info li {
  float: left;
  margin-left: 0.5em;
}

.sv-timeline-portlet .sv-meta-info li:first-child,
.sv-timelinesearch-portlet .sv-meta-info li:first-child,
.sv-tagtimeline-portlet .sv-meta-info li:first-child,
.sv-timelineentry-portlet .sv-meta-info li:first-child {
  margin-left: 0;
}

.sv-touch .sv-timeline-portlet .sv-meta-info a:nth-of-type(1),
.sv-touch .sv-timelinesearch-portlet .sv-meta-info a:nth-of-type(1),
.sv-touch .sv-tagtimeline-portlet .sv-meta-info a:nth-of-type(1),
.sv-touch .sv-timelineentry-portlet .sv-meta-info a:nth-of-type(1) {
  margin: 0;
}

.sv-touch .sv-timeline-portlet .sv-meta-info a,
.sv-touch .sv-timelinesearch-portlet .sv-meta-info a,
.sv-touch .sv-tagtimeline-portlet .sv-meta-info a,
.sv-touch .sv-timelineentry-portlet .sv-meta-info a {
  padding: 2px 6px;
  margin: 0 0 0 0.5em;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.sv-touch .sv-timeline-portlet .sv-meta-info li *:not(i),
.sv-touch .sv-timelinesearch-portlet .sv-meta-info li *:not(i),
.sv-touch .sv-tagtimeline-portlet .sv-meta-info li *:not(i) {
  vertical-align: middle;
}

.sv-timeline-portlet .sv-attachment,
.sv-timelinesearch-portlet .sv-attachment,
.sv-tagtimeline-portlet .sv-attachment,
.sv-timelineentry-portlet .sv-attachment {
  margin: 0 5px 5px 0;
  max-width: 100%;
  box-sizing: border-box;
}

.sv-timeline-portlet .sv-attachment *,
.sv-timelinesearch-portlet .sv-attachment *,
.sv-tagtimeline-portlet .sv-attachment *,
.sv-timelineentry-portlet .sv-attachment * {
  vertical-align: middle;
}

.sv-timeline-portlet .sv-attachment .sv-download,
.sv-timelinesearch-portlet .sv-attachment .sv-download,
.sv-tagtimeline-portlet .sv-attachment .sv-download,
.sv-timelineentry-portlet .sv-attachment .sv-download {
  display: none;
}

.sv-no-touch .sv-timeline-portlet .sv-attachment:hover .sv-download,
.sv-no-touch .sv-timelinesearch-portlet .sv-attachment:hover .sv-download,
.sv-no-touch .sv-tagtimeline-portlet .sv-attachment:hover .sv-download,
.sv-no-touch .sv-timelineentry-portlet .sv-attachment:hover .sv-download {
  display: inline;
}

/* Make sure message is contained */
.sv-timeline-portlet .sv-comment-content,
.sv-timelinesearch-portlet .sv-comment-content,
.sv-tagtimeline-portlet .sv-comment-content,
.sv-timelineentry-portlet .sv-comment-content,
.sv-timeline-portlet .sv-message-content,
.sv-tagtimeline-portlet .sv-message-content,
.sv-timelineentry-portlet .sv-message-content {
  word-wrap: break-word;
}

.sv-timeline-portlet .sv-comment-list,
.sv-timelinesearch-portlet .sv-comment-list,
.sv-tagtimeline-portlet .sv-comment-list,
.sv-timelineentry-portlet .sv-comment-list {
  padding: 0;
  margin: 1em 0;
  list-style: none;
}

.sv-fileshareall-portlet .sv-file-comments,
.sv-timelinesearch-portlet .sv-file-comments,
.sv-timeline-portlet .sv-timeline-comments,
.sv-tagtimeline-portlet .sv-timeline-comments,
.sv-timelineentry-portlet .sv-timeline-comments {
  padding: 0.5em 1em;
  background-color: whiteSmoke;
  border-bottom: 1px solid #ececec;
  box-sizing: border-box;
}

.sv-timeline-portlet .sv-shared-entry,
.sv-timelinesearch-portlet .sv-shared-entry,
.sv-tagtimeline-portlet .sv-shared-entry,
.sv-timelineentry-portlet .sv-shared-entry,
.sv-timeline-portlet .sv-shared-page,
.sv-tagtimeline-portlet .sv-shared-page,
.sv-timelineentry-portlet .sv-shared-page {
  border-left: 2px solid #eee;
  margin: 1em 0;
  padding-left: 1em;
}

.sv-no-touch .sv-fileshareall-portlet .sv-remove,
.sv-no-touch .sv-timelinesearch-portlet .sv-remove,
.sv-no-touch .sv-timeline-portlet .sv-remove,
.sv-no-touch .sv-tagtimeline-portlet .sv-remove,
.sv-no-touch .sv-timelineentry-portlet .sv-remove {
  display: none;
}

.sv-fileshareall-portlet .sv-remove,
.sv-timelinesearch-portlet .sv-remove,
.sv-timeline-portlet .sv-remove,
.sv-tagtimeline-portlet .sv-remove,
.sv-timelineentry-portlet .sv-remove {
  position: absolute;
  top: 0;
  right: 5px;
  text-decoration: none;
}

.sv-fileshareall-portlet .sv-timeline-entry,
.sv-timelinesearch-portlet .sv-timeline-entry,
.sv-timeline-portlet .sv-timeline-entry,
.sv-tagtimeline-portlet .sv-timeline-entry,
.sv-timelineentry-portlet .sv-timeline-entry,
.sv-fileshareall-portlet .sv-timeline-comment,
.sv-timeline-portlet .sv-timeline-comment,
.sv-tagtimeline-portlet .sv-timeline-comment,
.sv-timelineentry-portlet .sv-timeline-comment {
  position: relative;
}

.sv-timeline-portlet .sv-right-content,
.sv-timelinesearch-portlet .sv-right-content,
.sv-tagtimeline-portlet .sv-right-content,
.sv-timelineentry-portlet .sv-right-content {
  width: 87%;
  margin-left: 11%;
  margin-bottom: 0;
}

.sv-timeline-portlet .halflings-icon,
.sv-timelinesearch-portlet .halflings-icon,
.sv-timelineentry-portlet .halflings-icon,
.sv-tagtimeline-portlet .halflings-icon {
  vertical-align: middle;
}

.sv-timeline-portlet .sv-comment-input,
.sv-timelinesearch-portlet .sv-comment-input,
.sv-tagtimeline-portlet .sv-comment-input,
.sv-timelineentry-portlet .sv-comment-input {
  width: 100%;
  height: 20px;
  padding-top: 2px;
  display: block;
}

.sv-amount-of-files,
.sv-timeline-portlet .sv-entry-menu-wrapper,
.sv-timelinesearch-portlet .sv-entry-menu-wrapper,
.sv-tagtimeline-portlet .sv-entry-menu-wrapper,
.sv-timelineentry-portlet .sv-entry-menu-wrapper {
  display: none;
}

.sv-timeline-portlet .sv-entry-menu-wrapper.sv-show-menu,
.sv-timelinesearch-portlet .sv-entry-menu-wrapper.sv-show-menu,
.sv-tagtimeline-portlet .sv-entry-menu-wrapper.sv-show-menu,
.sv-timelineentry-portlet .sv-entry-menu-wrapper.sv-show-menu {
  display: inherit;
}

.sv-timeline-portlet .sv-comment-menu-wrapper,
.sv-timelinesearch-portlet .sv-comment-menu-wrapper,
.sv-tagtimeline-portlet .sv-comment-menu-wrapper,
.sv-timelineentry-portlet .sv-comment-menu-wrapper {
  display: none;
}

.sv-timeline-portlet .sv-comment-menu-wrapper.sv-show-menu,
.sv-timelinesearch-portlet .sv-comment-menu-wrapper.sv-show-menu,
.sv-tagtimeline-portlet .sv-comment-menu-wrapper.sv-show-menu,
.sv-timelineentry-portlet .sv-comment-menu-wrapper.sv-show-menu {
  display: inherit;
}

.sv-timeline-portlet .sv-disabled,
.sv-timelinesearch-portlet .sv-disabled,
.sv-tagtimeline-portlet .sv-disabled,
.sv-timelineentry-portlet .sv-disabled,
.sv-fileshareall-portlet .sv-disabled {
  opacity: 0.5;
}

.sv-timeline-portlet .sv-oembed-container,
.sv-timelinesearch-portlet .sv-oembed-container,
.sv-tagtimeline-portlet .sv-oembed-container,
.sv-timelineentry-portlet .sv-oembed-container,
.sv-fileshareall-portlet .sv-oembed-container {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  margin-top: 5px;
}

@media only screen and (max-width: 768px) {
  .sv-timelinesearch-portlet .sv-meta-info b,
  .sv-timeline-portlet .sv-meta-info b,
  .sv-tagtimeline-portlet .sv-meta-info b,
  .sv-timelineentry-portlet .sv-meta-info b {
    display: none;
  }
}

.sv-timeline-portlet .sv-timeline-gallery,
.sv-timelinesearch-portlet .sv-timeline-gallery,
.sv-tagtimeline-portlet .sv-timeline-gallery,
.sv-timelineentry-portlet .sv-timeline-gallery {
  margin: 1em 0;
}

.sv-files-added,
.sv-comment-files-added,
.sv-subcomment-files-added,
.sv-timeline-portlet .sv-timeline-gallery--multiple,
.sv-timelinesearch-portlet .sv-timeline-gallery--multiple,
.sv-tagtimeline-portlet .sv-timeline-gallery--multiple,
.sv-timelineentry-portlet .sv-timeline-gallery--multiple {
  display: flex;
  flex-wrap: wrap;
}

.sv-timeline-portlet .sv-timeline-gallery--multiple img,
.sv-timeline-portlet .sv-timeline-gallery--multiple video,
.sv-timelinesearch-portlet .sv-timeline-gallery--multiple img,
.sv-timelinesearch-portlet .sv-timeline-gallery--multiple video,
.sv-tagtimeline-portlet .sv-timeline-gallery--multiple img,
.sv-tagtimeline-portlet .sv-timeline-gallery--multiple video,
.sv-timelineentry-portlet .sv-timeline-gallery--multiple img,
.sv-timelineentry-portlet .sv-timeline-gallery--multiple video {
  flex: 1;
  height: 100px;
  margin: 0 5px 5px 0;
}

.sv-file-added,
.sv-comment-file-added,
.sv-subcomment-file-added {
  align-items: center;
  background-color: #e6e6e6;
  border: 1px solid #fff;
  padding: 5px;
  margin-right: 5px;
}

.sv-files-added,
.sv-comment-files-added,
.sv-subcomment-files-added {
  margin: 0 0 5px 0;
}

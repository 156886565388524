.sv-usercontacts-portlet .sv-list {
  list-style: none;
  position: relative;
  padding: 0;
  margin: 0 0 5px;
}

.sv-usercontacts-portlet .sv-list li {
  float: left;
}

.sv-usercontacts-portlet .sv-list li a {
  display: block;
}

.sv-usercontacts-portlet .sv-usercontacts-list-icon {
  height: 32px;
  width: 32px;
}

.sv-usercontacts-portlet .sv-usercontacts-badge::before {
  content: '(';
}

.sv-usercontacts-portlet .sv-usercontacts-badge::after {
  content: ')';
}

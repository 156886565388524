.sv-tasks-portlet .sv-fn-tasks {
  position: relative;
}

.sv-tasks-portlet .sv-fn-tasks,
.sv-tasks-portlet .sv-fn-tasks * {
  box-sizing: border-box;
}

form.sv-fn-event-edit.form-horizontal .control-group {
  margin-bottom: 10px;
}

.sv-tasks-portlet .sv-fn-tasks .sv-typeselector-button {
  margin-top: 1px;
  margin-right: 1px;
  float: right;
}

.sv-tasks-portlet .close {
  margin: 0;
}

.sv-tasks-portlet .popover-title {
  line-height: 25px;
}

.sv-tasks-portlet ol {
  list-style: none;
  margin: 0 0 10px 0;
  padding: 0;
}

.sv-tasks-portlet .sv-task-overdue {
  color: red;
}

.sv-tasks-portlet .input-append input.sv-tasks-input {
  height: 21px;
  width: 80%;
  box-sizing: content-box;
  font-size: 0.875rem;
  border-color: #949494;
}

.sv-tasks-portlet .input-append button {
  height: 30px;
}

.sv-tasks-portlet .input-prepend .add-on {
  height: 20px !important;
  margin-top: 2px;
}

.sv-no-touch .sv-tasks-portlet .sv-task:hover {
  background-color: #f5f5f5;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px #999;
}

.sv-tasks-portlet li.sv-task {
  display: block;
  padding: 5px 10px;
  border-radius: 4px;
  width: 100%;
  background-color: #f5f5f5;
  border: 1px solid #ececec;
  margin-top: 5px;
}

.sv-tasks-portlet .sv-task-wrapper {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.sv-tasks-portlet .sv-task-link {
  cursor: pointer;
  text-decoration: none;
  display: table-cell;
  width: 100%;
  padding-left: 25px;
}

.sv-tasks-portlet .sv-task-clock-cell {
  display: table-cell;
  width: 20px;
  padding-left: 10px;
}

.sv-tasks-portlet .bootstrap input.sv-task-toggle {
  margin-right: 6px;
  display: table-cell;
  width: 20px;
}

.sv-tasks-portlet .sv-task-text-container {
  display: inline-block;
  border-left: 1px solid #d0d0d0;
  margin-left: 20px;
  padding-left: 20px;
  padding-bottom: 10px;
}

.sv-tasks-portlet .sv-tasks-functions {
  padding-top: 1em;
  margin-top: 1em;
  border-top: 1px solid #eee;
}

.sv-tasks-portlet .sv-tasks-functions a {
  text-decoration: none;
}

.sv-tasks-portlet .sv-tasks-functions a:hover {
  text-decoration: underline;
}

.sv-tasks-portlet .sv-percentDone {
  color: #999;
}

.sv-tasks-portlet .sv-more {
  cursor: pointer;
  font-size: smaller;
  font-weight: bold;
}
.sv-tasks-portlet .sv-show-scroll {
  overflow: auto;
  position: relative;
  margin-right: -14px;
}

.sv-tasks-portlet .sv-task-user-icon {
  vertical-align: middle !important;
}

.sv-tasks-portlet .sv-task-title {
  word-wrap: break-word;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.sv-tasks-portlet .sv-task-title:hover {
  text-decoration: none;
}

.sv-tasks-portlet .sv-task-title-owner {
  font-size: 0.875rem;
}

.sv-tasks-portlet .sv-task-title-owner:hover {
  text-decoration: underline;
}

.sv-tasks-portlet .sv-tasks-drowdown-button {
  font-size: 0.875rem;
  line-height: initial;
}

.sv-fn-tasks .sv-typeselector-button .sv-tasks-dropdown-button {
  font-size: 0.6875rem;
}

.modal .form-horizontal .sv-tasks-form-label {
  font-size: 0.875rem;
}

.modal .form-horizontal .sv-tasks-form-input {
  font-size: 0.875rem;
  border-color: #949494;
}

@media (max-width: 580px) {
  .sv-tasks-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .modal .sv-tasks-form-container .controls {
    margin-left: 0;
  }

  .modal .sv-tasks-form-container .sv-tasks-form-label {
    float: none;
    text-align: revert;
  }

  .sv-tasks-form-checkbox {
    width: 284px;
  }

  .sv-tasks-form-date {
    width: 284px;
  }
}

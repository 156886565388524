/**
 * Copyright (C) SiteVision AB 2002-2012, all rights reserved
 *
 * @author Karl Eklöf
 */

.sv-groupadminall-portlet .sv-list-5-columns,
.sv-groupadminall-portlet .sv-list-2-columns {
   list-style:none;
   padding:0;
   margin:0
}

.sv-groupadminall-portlet .sv-usersearch-popover h3 {
   display:block !important;
}

.sv-groupadminall-portlet .sv-list-5-columns li.sv-groupadminall-member {
   float:left;
   width:20%
}

.sv-groupadminall-portlet .caret {
   vertical-align:baseline !important
}
.sv-groupadminall-portlet .downdown-toggle {
   text-decoration:none
}

@media only screen and (max-width:768px) {
   .sv-groupadminall-portlet .sv-list-5-columns li.sv-groupadminall-member {
      width:25%
   }
}

.sv-groupadminall-portlet .sv-list-2-columns li.sv-groupadminall-member {
   float:left;
   width:50%
}

@media only screen and (max-width:500px) {
   .sv-groupadminall-portlet .sv-list-5-columns li.sv-groupadminall-member {
      width:33%
   }

   .sv-groupadminall-portlet .sv-filter-field {
      width:80px
   }

   .sv-groupadminall-portlet .sv-list-2-columns li.sv-groupadminall-member {
      float:none;
      width:100%
   }
}

.sv-groupadminall-portlet li.sv-groupadminall-member {
   box-sizing:border-box;
   padding:0;
   margin:0
}

/* default grid */
.sv-groupadminall-portlet li.sv-groupadminall-member {
   text-align:center;
   padding:8px;
   word-wrap:break-word
}
.sv-groupadminall-portlet .sv-groupadminall-member-status {
   color: #999999;
   font-weight:normal
}

.sv-groupadminall-portlet .sv-list-5-columns .sv-contact-name {
   margin-bottom:0;
   height:2.5em;
   overflow:hidden;
   display:block
}

.sv-groupadminall-portlet .sv-list-5-columns .sv-contact-img {
   width:48px;
   height:48px
}

.sv-groupadminall-portlet .sv-list-2-columns .sv-contact-userfield {
   overflow:hidden
}

.sv-groupadminall-portlet .sv-list-5-columns .sv-groupadminall-member-status,
.sv-groupadminall-portlet .sv-list-5-columns .sv-contact-userfield {
   display:none
}

.sv-groupadminall-portlet .sv-list-2-columns .sv-contact-userfield br:last-child {
   display:none
}

/* list  2 - columns */
.sv-groupadminall-portlet .sv-list-2-columns li.sv-groupadminall-member {
   text-align:left;
   padding:8px;
   word-wrap:break-word
}

.sv-groupadminall-portlet .sv-list-2-columns .sv-contact-name {
   margin:0 0 4px 0;
   display:block
}

.sv-groupadminall-portlet .sv-list-2-columns .sv-contact-img {
   width:48px;
   height:48px;
   margin:0
}

.sv-groupadminall-portlet .sv-controls {
   width:100%;
   padding:5px 0px
}

.sv-groupadminall-portlet .sv-controls .sv-list-style-switch {
   padding-top:2px;
   float:left
}

.sv-no-touch .sv-groupadminall-portlet .sv-social-contact:hover {
   min-height:20px;
   background-color:#F5F5F5;
   border-radius:4px;
   box-shadow:inset 0 1px 1px #999
}

.sv-groupadminall-portlet .sv-social-contact {
   position:relative;
   cursor:pointer
}

.sv-no-touch .sv-groupadminall-portlet .sv-social-contact:hover .sv-remove {
   display:inherit
}

.sv-no-touch .sv-groupadminall-portlet .sv-remove {
   display:none
}

.sv-groupadminall-portlet .sv-remove {
   position:absolute;
   top:5px;
   right:5px;
   text-decoration:none
}

.sv-groupadminall-portlet .sv-filter-field {
   margin-top:2px
}

.sv-groupadminall-portlet .sv-filter-label {
   margin:5px 1em 0 0
}
